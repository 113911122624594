import React from "react";
import './ImageWithText.css';
import { Link } from "react-router-dom";
import bannerImage from '../../assets/homepage/acc1.webp';
import banner1 from '../../assets/homepage/usp1.jpg';


const ImageWithText = () => {

      return (
        <section className="about-section">
        <div className="container">
            <div className="row" >                
                <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2" data-aos="fade-left">
                    <div className="inner-column">
                        <div className="sec-title">
                            <span className="title">About Us</span>
                            <h2>R Solar - Changing Lives</h2>
                        </div>
                        <div className="text">Welcome to R Solar, your trusted partner in harnessing the boundless power of the sun for a sustainable future. As an approved seller for Clean Energy systems accredited by the Clean Energy Council, we pride ourselves on providing cutting-edge solar solutions tailored to meet the unique needs of our Australian customers.</div>
                        <div className="text">With over a decade of combined experience in the solar industry, our team at R Solar has successfully designed and implemented over 100 solar panel installations across Victoria, Australia. Our commitment to reshaping the energy landscape is unwavering, as we strive to empower homes and businesses to embrace cleaner, renewable energy sources.</div>
                        <div className="text">At R Solar, we are driven by a passion for environmental stewardship and a vision to make solar energy accessible to all. Our dedication to excellence is reflected in our outstanding track record of customer satisfaction. We go beyond mere installations, offering comprehensive support and guidance to ensure our customers make informed decisions about their energy choices. </div>
                        <div className="text">Join us at R Solar as we pave the way towards a brighter, more sustainable future for Australia and beyond. </div>
                        <div className="btn-box">
                        <Link to="/contact">CONTACT US</Link>
                        </div>
                    </div>
                </div>

                
                <div className="image-column col-lg-6 col-md-12 col-sm-12 mt-1" data-aos="fade-right" >
                    <div className="inner-column" >
                      <figure className="image-1" ><img title="solar panel" src={banner1} alt="" /></figure>
                     
                    </div>
                </div>
              
            </div>
            <div class="sec-title">
                            <span class="title" data-aos="fade-right">Our Future Goal</span>
                            <h2 data-aos="fade-left">Leading Innovation and Technology in Solar Panels</h2>
                        </div>
                        <div className="text" data-aos="fade-up">
                        At R Solar, our mission goes beyond mere installations – we're committed to empowering homes and businesses to embrace renewable energy sources. Through top-of-the-line rooftop, ground-mounted, and carport solar systems, we strive to reshape the energy landscape and make clean energy accessible to all. Customer satisfaction lies at the heart of everything we do, reflected in our exemplary track record and dedication to providing comprehensive support and education to our clients.
                       </div>
                        <div className="text" data-aos="fade-up">
                        Central to our approach is the emphasis on informed decision-making when it comes to solar panel system choices. Our experienced consultants carefully assess each client's needs, considering factors such as roof structure, home layout, and electricity usage patterns. By conducting thorough analyses and recommending cost-effective solutions, we enable our clients to maximize their return on investment while making responsible lifestyle choices. Join us at R Solar as we embark on a transformative journey towards a brighter, more sustainable Australia powered by the sun.
                        </div>
                        <ul className="usp-list" >
                            <li data-aos="fade-right">Custom Solar Enery Systems</li> 
                            <li data-aos="fade-right"> End-To-End Solutions</li> 
                            <li data-aos="fade-left" > Team of Experts & Innovators</li> 
                            <li data-aos="fade-left"> Satisfaction Money Solution</li>
                        </ul>
            </div>
            </section>
      );
}
export default ImageWithText;