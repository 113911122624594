import { Link } from 'react-router-dom';
import './NavLinks.css'
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import banner1 from '../../../assets/homepage/tige2.png';
import banner2 from '../../../assets/homepage/VertexSplus.png';
import banner3 from '../../../assets/homepage/rec_website_tp5.jpg';
import banner4 from '../../../assets/homepage/enphase_invertors.webp';
import banner5 from '../../../assets/homepage/froniusSolarInvertor.png';
import banner6 from '../../../assets/homepage/sungrow_solar_inverter.webp';
import banner7 from '../../../assets/homepage/byd_battery.png';
import banner8 from '../../../assets/homepage/tesla_battery.png';
import banner9 from '../../../assets/homepage/sungrow_battery.png';
import banner10 from '../../../assets/homepage/iStore_heatpump.png';
import banner11 from '../../../assets/homepage/reclaim_heatpump1.png';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Icon } from '@mui/material';

const NavLinks = () => {

    const [headerData, setHeaderData] = useState([
        {
          label: 'Solar Panels',
          href: '#',
          children: [
            {
              label: 'Jinko ',
              imgSrc: banner1,
              linkTo: '/products/jinko-solar',
              description: 'With the Tiger Neo 54HL4-V, JinkoSolar is launching a highly efficient and powerful solar module for private and commercial photovoltaic systems. With a rated output of up to 425 watts and an efficiency of up to 21.76%, the module demonstrates its outstanding performance even in diffuse light conditions, thus ensuring an excellent energy yield. In addition, its robust design and resistance to adverse weather conditions make it the ideal choice for anyone who values performance, quality and reliability.'
            },
            {
              label: 'Trina ',
              imgSrc: banner2,
              linkTo: '/products/trina-solar',
              description: 'The upgraded Vertex S+ offers outstanding product performance combined with innovative, design-oriented aesthetics thanks to the transparent double-glazed concept. The bifacial double glazing technology improves durability, fire resistance, performance and recyclability. Combined with the new N-type i-TOPCon cell technology, this is the next generation of solar modules for residential and commercial rooftop installations.'
            },
            {
              label: 'REC ',
              imgSrc: banner3,
              linkTo: '/products/rec-solar',
              description: "Based on the multiple award-winning REC TwinPeak technology, REC TwinPeak 5 panels bring heightened efficiency to REC's portfolio. Building on REC's long experience with half-cut cell technology, the REC TwinPeak 5 Series uses p-type monocrystalline cells, offering an even greater efficiency and power, making this Series suitable for all applications and system sizes, anywhere in the world! REC TwinPeak 5 features REC's innovative design with the high panel efficiency and power output of monocrystalline cells, enabling you to get more out of the space available – helping to lower your energy bills and shorten the payback time through increased yield and lower costs."
            },
          ],
        },
        {
          label: 'Solar Inverters',
          href: '#',
          children: [
            {
              imgSrc: banner4,
              label: 'Enphase',
              linkTo: '/products/enphase-invertor',
              description: "The high-powered, smart grid-ready IQ8HC Microinverters are designed to match the latest generation high output PV modules. The IQ8HC Microinverters have the highest energy production and reliability standards in the industry, and with rapid shutdown functionality, it meets the highest safety standards. The brain of the semiconductor-based microinverter is our proprietary, application-specific integrated circuit (ASIC) that enables the microinverter to operate in a grid-connected mode. IQ8 Series Microinverters redefine reliability standards with more than one million cumulative hours of power-on testing, enabling an industry-leading limited warranty of up to 25 years."
    
            },
            {
              imgSrc: banner5,
              label: 'Fronius',
              linkTo: '/products/fronius-invertor',
              description: "Fronius inverters are efficient and reliable and form the indispensable heart of every photovoltaic system. Power categories ranging from 1.5 to 27.0 kW guarantee suitability for virtually any system size – from a family home to a large-scale system."
    
            },
            {
              imgSrc: banner6,
              label: 'Sungrow',
              linkTo: '/products/sungrow-invertor',
              description: "Sungrow was established from the ground up as a PV inverter manufacturer. Their SG series are both transformerless and lightweight string inverters with Multiple Power Point Tracking. Sungrow inverters achieve higher yields with fantastic efficiency and feature surge protection and PV isolators for consumer safety."
    
            }
          ],
        },
        {
          label: 'Solar Batteries',
          href: '#',
          children: [
            {
              imgSrc: banner7,
              label: 'BYD',
              linkTo: '/products/byd-battery',
              description: "The new BYD Battery-Box Premium HVS 7.7 battery storage system generation builds on the well-known memories and has all previous functions. The Battery-Box Premium HVS is a battery module that has higher storage capacities than its predecessor. The further development of cell technology has reduced the system weight by almost 30%. The construction depth was also reduced by 8 cm. This means that less floor space is required. At the same time, the new devices have a leaner look. One of the highlights: The new storage systems have 'protection class IP55'. This enables use outside as well."
    
            },
            {
              imgSrc: banner8,
              label: 'Tesla',
              linkTo: '/products/tesla-solar',
              description: "The Tesla Powerwall is a rechargeable lithium-ion battery energy storage product designed for residential and light commercial use. It stores electricity generated from solar panels or from the grid during off-peak times for use during peak demand periods or when the grid is down. The Powerwall can also be used to power essential appliances during power outages, providing backup power and increasing energy independence. Its sleek design and smart energy management features make it a popular choice for homeowners looking to reduce their reliance on traditional utility power and embrace clean energy solutions."
    
            },
            {
              imgSrc: banner9,
              label: 'Sungrow',
              linkTo: '/coming-soon',
              description: "Regarding battery performance and reliability, Sungrow strives to deliver high-quality and reliable energy storage solutions. Sungrow utilizes advanced battery chemistries, typically Lithium-ion, in their energy storage systems. These batteries offer high energy density, long cycle life, and excellent performance, ensuring reliability and longevity. Among their range of products, the Sungrow 9.6kWh battery stands out as one of their most popular offerings."
    
            }
          ],
        },
        {
          label: 'Heat Pumps',
          href: '#',
          children: [
            {
              imgSrc: banner10,
              label: 'iStore',
              linkTo: '/products/iStore-heatpump',
              description: "Offering premium efficiency and performance, the iStore 180 L - Air to Energy is the perfect choice for smaller homes that want the best in hot water all year round, powered by the sun's rays. The iStore consumes 75% - 85% less energy than conventional hot water systems. External wrap around heating coil provides maximum thermal energy transfer."
    
            },
            {
              imgSrc: banner11,
              label: 'Reclaim',
              linkTo: '/coming-soon',
              description: "Reclaim Energy’s high efficiency Q-TON Commercial Heat Pump is an air-to-water heat pump using CO2 gas as a refrigerant. It can be used in a variety of applications for the supply of sanitary hot water. The Q-TON Commercial Heat Pump is the world’s first two-stage compressor combining rotary and scroll technology to maintain high efficiency and significantly improve performance at cooler air temperatures."
    
            },
          ]
        }
        // ...
      ])
    return (
        <nav className="nav__bottom__container megaMenuSection">
            {/* <div href="#home" className='navbrand__container'>
                <h1 className='navbrand'>
                    <Link to="/">R Solar</Link>
                </h1>
            </div> */}
            <div className="bottom__container">
                <ul className="nav nav-parent-custom">
                    {
                        headerData.map(header => {
                            return (
                                <>
                                    <li className='nav-link mega-menu-label'><Link className='header-menu-label montserrat-bold' to="/">{header.label}</Link>
                                        <ul className='nav-child-container'>


                                            <Swiper
                                                slidesPerView={2.5}
                                                spaceBetween={30}
                                                pagination={{
                                                    clickable: true,
                                                }}

                                                breakpoints={{
                                                    0: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 20,
                                                    },
                                                    640: {
                                                        slidesPerView: 1.3,
                                                        spaceBetween: 20,
                                                    },
                                                    768: {
                                                        slidesPerView: 2.5,
                                                        spaceBetween: 20,
                                                    },
                                                    1024: {
                                                        slidesPerView: 3.5,
                                                        spaceBetween: 20,
                                                    },
                                                }}
                                                modules={[Pagination]}
                                                className="mySwiper featured-swiper"
                                            >

                                                {header.children?.map((childMenu, index) => (
                                                    <SwiperSlide className="list">
                                                        <div key={index} className="item" >
                                                            <div className="wrapper">
                                                                <div className="container">
                                                                    <div className="top">
                                                                        <img src={childMenu.imgSrc} alt={`Slide ${index}`} />
                                                                    </div>
                                                                    <div className="bottom">
                                                                        <div className="left">
                                                                            <Link to={childMenu.linkTo}>
                                                                                <div className="details ">
                                                                                    <h3 className='montserrat-regular'>{childMenu.label} 
                                                                                    </h3>
                                                                                    <img className='arrow-image-product-card' width="20" height="20" src="https://img.icons8.com/ios/50/FFFFFF/long-arrow-right--v1.png" alt="long-arrow-right--v1"/>
                                                                                </div>
                                                                                {/* <div className="buy"><i className="material-icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 512 512" id="cart"><path fill="#FF973B" d="M169.6 377.6c-22.882 0-41.6 18.718-41.6 41.601 0 22.882 18.718 41.6 41.6 41.6s41.601-18.718 41.601-41.6c-.001-22.884-18.72-41.601-41.601-41.601zM48 51.2v41.6h41.6l74.883 151.682-31.308 50.954c-3.118 5.2-5.2 12.482-5.2 19.765 0 27.85 19.025 41.6 44.825 41.6H416v-40H177.893c-3.118 0-5.2-2.082-5.2-5.2 0-1.036 2.207-5.2 2.207-5.2l20.782-32.8h154.954c15.601 0 29.128-8.317 36.4-21.836l74.882-128.8c1.237-2.461 2.082-6.246 2.082-10.399 0-11.446-9.364-19.765-20.8-19.765H135.364L115.6 51.2H48zm326.399 326.4c-22.882 0-41.6 18.718-41.6 41.601 0 22.882 18.718 41.6 41.6 41.6S416 442.082 416 419.2c0-22.883-18.719-41.6-41.601-41.6z"></path></svg>
                      </i>
                      </div> */}
                                                                            </Link>
                                                                        </div>
                                                                        <div className="right">
                                                                            <div className="done"><i className="material-icons">done</i></div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="inside">
                                                                    <div className="icon"><i className="material-icons">
                                                                        <svg width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="info"><g fill="#000" data-name="Layer 2"><path d="M8 2a6 6 0 1 0 6 6 6 6 0 0 0-6-6Zm0 11a5 5 0 1 1 5-5 5 5 0 0 1-5 5Z"></path><path d="M8 6.85a.5.5 0 0 0-.5.5v3.4a.5.5 0 0 0 1 0v-3.4a.5.5 0 0 0-.5-.5zM8 4.8a.53.53 0 0 0-.51.52v.08a.47.47 0 0 0 .51.47.52.52 0 0 0 .5-.5v-.12A.45.45 0 0 0 8 4.8z"></path></g></svg>
                                                                    </i></div>
                                                                    <div className="contents lato-regular">
                                                                        {childMenu.description}
                                                                    </div>
                                                                </div> */}
                                                            </div>

                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>



                                        </ul>
                                    </li >
                                </>
                            )
                        })
                    }
                </ul>
            </div>
        </nav >
    );
}

export default NavLinks;