import React, { useState } from 'react';
import './USP.css';
import banner1 from '../../assets/homepage/usp1.jpg';
import banner2 from '../../assets/homepage/usp2.jpg';
import banner3 from '../../assets/homepage/usp3.jpg';
import defaultbanner from '../../assets/homepage/defaultUsp.jpg';
import { Accordion } from 'react-bootstrap';

const PlusIcon = () => (
  <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.132812 20.5H41.1328" stroke="currentColor" strokeWidth="1.5"></path>
  <path d="M20.6328 41L20.6328 0" stroke="currentColor" strokeWidth="1.5" className="Item_vertical__rZGRq"></path>
</svg>
);

const MinusIcon = () => (
  <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.132812 20.5H41.1328" stroke="currentColor" strokeWidth="1.5"></path>
  </svg>
);


const USP = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const questions = [
    {
        image: banner1,
        question: 'Reliability and Durability',
        answer: "Emphasize the reliability and durability of your products, showcasing features such as robust construction, weather resistance, and proven performance in diverse environmental conditions for long-term reliability and peace of mind."
      },
      {
        image: banner2,
        question: 'Environmental Sustainability',
        answer: "Highlight the environmental benefits of solar energy, including reduced carbon footprint, energy independence, and contribution to a cleaner and greener planet, appealing to environmentally conscious consumers."
      },
      {
        image: banner3,
        question: 'Customized Solar Solutions',
        answer: "Tailor solar panel systems, inverters, and batteries to meet specific customer needs, whether for residential, commercial, or industrial applications, providing personalized solutions for every project."
      }
    // Add more questions as needed
  ];

  const handleToggle = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className="accordion1 usp-section-custom" >
      <div className="accordion-questions" data-aos="fade-right">
      <Accordion defaultActiveKey="0">
  
        {questions.map((item, index) => (
          <Accordion.Item eventKey={index} className={`accordion-question ${index === expandedIndex ? 'active' : ''}`}>
            <Accordion.Header className="question" onClick={() => handleToggle(index)}>
              <h3 className='montserrat-bold'>{item.question}</h3>
              <span>  {index === expandedIndex ? <MinusIcon /> : <PlusIcon />}</span>
            </Accordion.Header>
           
              <Accordion.Body className="answer montserrat-regular">
                {item.answer}
              </Accordion.Body>
           
          </Accordion.Item>
        ))}
        </Accordion>
      </div>
      <div className="accordion-image" data-aos="fade-left">
        <img src={expandedIndex !== null ? questions[expandedIndex].image : defaultbanner} alt="Accordion Image" />
      </div>
    </div>
  );
};

export default USP;
