import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './TeslaSolar.css';
import TeslaPDPDesk from '../../assets/productpages/Fronius1.jpg';
import TeslaPDPMob from '../../assets/productpages/Fronius1.jpg';
import TeslaMobSwiper1 from '../../assets/productpages/Fronius1.jpg';
import TeslaMobSwiper2 from '../../assets/productpages/powerwall2img2mob.jpg'
import TeslaDeskSwiper1 from '../../assets/productpages/powerwall2img1.jpg'
import TeslaDeskSwiper2 from '../../assets/productpages/powerwall2img2.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade, Navigation, Pagination } from 'swiper/modules';

import teslaPowerwall from '../../assets/productpages/Fronius1.jpg';

const FroniusInvertor = () => {
  return (
    <div className='product-page-super-parent'>
      <div className="custom-banner-section carousel">
        <div className="list">
          <div className="item" >
          {/* <video autoPlay loop muted controls className='product-main-banner'>
            <source src={teslabanner} type="video/mp4"/>
          </video> */}
          <div className='DesktopBannerTesla'>
            <img src={TeslaPDPDesk} alt='DesktopBanner' width={1920} height={1080}/>
          </div>
          <div className='MobileBannerTesla'>
            <img src={TeslaPDPMob} alt='MobileBanner' width={720} height={1280}/>
          </div>
            <div className="content MobBannerContent">
              <div className="title montserrat-bold">Fronius Solar Invertors</div>
              <div className="des montserrat-regular">Fronius Symo inverters are available in power categories ranging from 3 kW to 20 kW. They are transformerless three-phase inverters, ideal for every system size. With the SuperFlex Design, the Fronius Symo is the perfect answer to irregularly shaped or different roof orientations. The high system voltage, wide input voltage range, and two MPP trackers provide maximum flexibility in system design.</div>
              <div class="buttons">
                <Link to='/get-quote'>
                  <button className='lato-bold'>Get Started</button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-brand'>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-e4817c9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e4817c9" data-element_type="section">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b45e63c" data-id="b45e63c" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-9154ea2 elementor-widget elementor-widget-image" data-id="9154ea2" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container DeskTopTesla">
                    <img src={TeslaMobSwiper1} />
                  </div>
                  <div class="elementor-widget-container MobileTesla">
                    <img src={teslaPowerwall} />
                  </div>
                </div>
              </div>
              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4558e86" data-id="4558e86" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6b5e14b elementor-widget elementor-widget-heading" data-id="6b5e14b" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Maximizing Solar Power with Precision and Reliability</h2>		</div>
                  </div>
                  <div class="elementor-element elementor-element-3e6203e elementor-widget elementor-widget-text-editor" data-id="3e6203e" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>Fronius Solar Inverters set the standard for excellence in solar energy conversion, delivering superior efficiency, reliability, and advanced technological features. As products of Fronius, a renowned leader in solar energy solutions, these inverters are engineered to transform direct current (DC) generated by solar panels into alternating current (AC) with unmatched precision. This process ensures that the maximum amount of clean, renewable energy is available for use in homes, businesses, and industrial applications.</p>						
                      <p> </p>
                      <p>Fronius inverters are celebrated for their robust design and intelligent energy management systems. They seamlessly integrate with the grid, ensuring stable and efficient energy supply even under varying conditions. Fronius Solar Inverters combine high efficiency, advanced technology, and robust design to deliver optimal solar energy conversion. Ideal for residential, commercial, and industrial applications, these inverters ensure reliable performance, ease of use, and comprehensive monitoring capabilities. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <div className='product-usp-sectionDesk'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TeslaDeskSwiper2} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='product-usp-sectionMob'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} alt='MobileSwiper1' />
          </SwiperSlide>
          <SwiperSlide>
          <img  src={TeslaMobSwiper2} alt='MobileSwiper2' />
          </SwiperSlide>
        </Swiper>
      </div> */}
      <div className='product-specification'>
        <h2>Fronius Specification</h2>
        <div className='specification-parent'>
          <div className='specification-child'>
            <h3>Power</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Max. DC Power </h5>
                <p>7.5 kW </p>
              </div>
              <div className='spec-card'>
                <h5>Max. AC Power</h5>
                <p>4.6 kW</p>
              </div>
              <div className='spec-card'>
                <h5>Operating Temperature</h5>
                <p>-40 ~ +55 ℃ </p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Features</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Transformer</h5>
                <p>Transformerless</p>
              </div>
              <div className='spec-card'>
                <h5>Protection Class </h5>
                <p>IP65</p>
              </div>
              <div className='spec-card'>
                <h5>Humidity</h5>
                <p>0-100 % </p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Warranty</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Duration</h5>
                <p>5 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FroniusInvertor