import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './TeslaSolar.css';
import TeslaPDPDesk from '../../assets/productpages/iStore1.jpg';
import TeslaPDPMob from '../../assets/productpages/iStore1.jpg';
import TeslaMobSwiper1 from '../../assets/productpages/iStore1.jpg';
import TeslaMobSwiper2 from '../../assets/productpages/powerwall2img2mob.jpg'
import TeslaDeskSwiper1 from '../../assets/productpages/powerwall2img1.jpg'
import TeslaDeskSwiper2 from '../../assets/productpages/powerwall2img2.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade, Navigation, Pagination } from 'swiper/modules';

import teslaPowerwall from '../../assets/productpages/iStore1.jpg';

const IstoreHeadPump = () => {
  return (
    <div className='product-page-super-parent'>
      <div className="custom-banner-section carousel">
        <div className="list">
          <div className="item" >
          {/* <video autoPlay loop muted controls className='product-main-banner'>
            <source src={teslabanner} type="video/mp4"/>
          </video> */}
          <div className='DesktopBannerTesla'>
            <img src={TeslaPDPDesk} alt='DesktopBanner' width={1920} height={1080}/>
          </div>
          <div className='MobileBannerTesla'>
            <img src={TeslaPDPMob} alt='MobileBanner' width={720} height={1280}/>
          </div>
            <div className="content MobBannerContent">
              <div className="title montserrat-bold">iStore Heat Pumps</div>
              <div className="des montserrat-regular">iStore Heat Pumps are innovative hot water solutions designed for energy-efficient and eco-friendly water heating needs. Using advanced heat pump technology, iStore extracts heat energy from the surrounding air and transfers it to heat water, offering substantial energy savings compared to traditional water heaters. These systems are highly efficient, producing hot water even in colder climates while reducing electricity consumption and carbon emissions. </div>
              <div class="buttons">
                <Link to='/get-quote'>
                  <button className='lato-bold'>Get Started</button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-brand'>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-e4817c9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e4817c9" data-element_type="section">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b45e63c" data-id="b45e63c" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-9154ea2 elementor-widget elementor-widget-image" data-id="9154ea2" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container DeskTopTesla">
                    <img src={TeslaMobSwiper1} />
                  </div>
                  <div class="elementor-widget-container MobileTesla">
                    <img src={teslaPowerwall} />
                  </div>
                </div>
              </div>
              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4558e86" data-id="4558e86" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6b5e14b elementor-widget elementor-widget-heading" data-id="6b5e14b" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Efficient Hot Water, Sustainable Living: iStore Heat Pumps</h2>		</div>
                  </div>
                  <div class="elementor-element elementor-element-3e6203e elementor-widget elementor-widget-text-editor" data-id="3e6203e" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>iStore Heat Pumps are leading the way in energy-efficient water heating, providing reliable hot water while reducing environmental impact. By harnessing the heat energy from the air, iStore Heat Pumps offer a sustainable alternative to traditional water heating methods. These systems are designed to operate efficiently in various climates, ensuring hot water is available year-round with significant energy savings. </p>						
                      <p> </p>
                      <p>Upgrade to iStore Heat Pumps and experience the future of hot water solutions. Whether for your home or business, iStore offers reliable performance, lower energy bills, and reduced carbon footprint. With smart controls and high-efficiency operation, iStore Heat Pumps optimize energy usage, ensuring hot water is available whenever needed. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <div className='product-usp-sectionDesk'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TeslaDeskSwiper2} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='product-usp-sectionMob'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} alt='MobileSwiper1' />
          </SwiperSlide>
          <SwiperSlide>
          <img  src={TeslaMobSwiper2} alt='MobileSwiper2' />
          </SwiperSlide>
        </Swiper>
      </div> */}
      <div className='product-specification'>
        <h2>iStore Specification</h2>
        <div className='specification-parent'>
          <div className='specification-child'>
            <h3>Power</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Heating capacity</h5>
                <p>3.4kW</p>
              </div>
              <div className='spec-card'>
                <h5>Water tank capacity</h5>
                <p>270 L</p>
              </div>
              <div className='spec-card'>
                <h5>Power input</h5>
                <p>0.94kW</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Features</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Running current</h5>
                <p>3.97A</p>
              </div>
              <div className='spec-card'>
                <h5>Water inlet / outlet size</h5>
                <p>20 BSB / mm</p>
              </div>
              <div className='spec-card'>
                <h5>Back up element</h5>
                <p>1.5kW</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Warranty</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Duration</h5>
                <p>5 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IstoreHeadPump