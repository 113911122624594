import { Fragment, useEffect, useState } from "react";

import 'swiper/css';
import './InfoContainer.css'
import Form from "../Form/Form";
import solarPanel from '../../assets/homepage/solarImage.jpeg'
import banner1 from '../../assets/homepage/forHome.jpg'




const InfoContainer = () => {
  return (

    <div className="wrapper InfoContainer "  >
      <h2 className="section-title" data-aos="fade-right" >Embrace the Power of the Sun</h2>
      <div className="content" data-aos="fade-left">
        <p className="montserrat-regular">Solar energy is a sustainable and eco-friendly method of powering your life. Unlike traditional electricity production, which emits harmful pollutants, solar energy utilizes clean rays from the sun. Its easy installation on rooftops makes it a hassle-free option for all.</p>
      </div>
      <div className="form-content-div">
        <div className="left-box" data-aos="fade-right">

          <img src={solarPanel} alt="Solar Panel" />
        </div>

        <div className="right-box" data-aos="fade-left">
          <Form />
        </div>
      </div>
    </div>

  );
}

export default InfoContainer;