import { Fragment, useEffect, useState } from "react";

import 'swiper/css';

import GetQuote from "../my-components/GetQuote/GetQuote"



import { TabTitle } from "../utils/General";


const GetQuotePage = () => {
   
    TabTitle("Get Quote");

    return ( 
        <Fragment>
            
           <GetQuote />
         
        </Fragment>
    );
}
 
export default GetQuotePage;