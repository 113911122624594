import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './TeslaSolar.css';
import TeslaPDPDesk from '../../assets/productpages/iStore1.jpg';
import TeslaPDPMob from '../../assets/productpages/iStore1.jpg';
import TeslaMobSwiper1 from '../../assets/productpages/iStore1.jpg';
import TeslaMobSwiper2 from '../../assets/productpages/powerwall2img2mob.jpg'
import TeslaDeskSwiper1 from '../../assets/productpages/powerwall2img1.jpg'
import TeslaDeskSwiper2 from '../../assets/productpages/powerwall2img2.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade, Navigation, Pagination } from 'swiper/modules';

import teslaPowerwall from '../../assets/productpages/iStore1.jpg';

const ReclaimHeatpump = () => {
  return (
    <div className='product-page-super-parent'>
      <div className="custom-banner-section carousel">
        <div className="list">
          <div className="item" >
          {/* <video autoPlay loop muted controls className='product-main-banner'>
            <source src={teslabanner} type="video/mp4"/>
          </video> */}
          <div className='DesktopBannerTesla'>
            <img src={TeslaPDPDesk} alt='DesktopBanner' width={1920} height={1080}/>
          </div>
          <div className='MobileBannerTesla'>
            <img src={TeslaPDPMob} alt='MobileBanner' width={720} height={1280}/>
          </div>
            <div className="content MobBannerContent">
              <div className="title montserrat-bold">Reclaim Heat Pumps</div>
              <div className="des montserrat-regular">Reclaim heat pumps are innovative heating and cooling systems designed to efficiently extract heat from indoor and outdoor air, providing reliable comfort while minimizing energy consumption. Using advanced technology, these heat pumps can operate in both heating and cooling modes, offering year-round climate control for residential and commercial spaces. Reclaim heat pumps are known for their high efficiency, quiet operation, and environmentally friendly refrigerants, making them a sustainable choice for reducing carbon emissions and energy costs. </div>
              <div class="buttons">
                <Link to='/get-quote'>
                  <button className='lato-bold'>Get Started</button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-brand'>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-e4817c9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e4817c9" data-element_type="section">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b45e63c" data-id="b45e63c" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-9154ea2 elementor-widget elementor-widget-image" data-id="9154ea2" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container DeskTopTesla">
                    <img src={TeslaMobSwiper1} />
                  </div>
                  <div class="elementor-widget-container MobileTesla">
                    <img src={teslaPowerwall} />
                  </div>
                </div>
              </div>
              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4558e86" data-id="4558e86" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6b5e14b elementor-widget elementor-widget-heading" data-id="6b5e14b" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Efficient Comfort, Sustainable Solutions: Reclaim Heat Pumps</h2>		</div>
                  </div>
                  <div class="elementor-element elementor-element-3e6203e elementor-widget elementor-widget-text-editor" data-id="3e6203e" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>Reclaim Heat Pumps revolutionize indoor climate control with their efficient and sustainable heating and cooling solutions. By harnessing the energy from both indoor and outdoor air, these innovative systems offer reliable comfort year-round while significantly reducing energy consumption and carbon footprint. Reclaim heat pumps are engineered with advanced technology and environmentally friendly refrigerants, ensuring optimal performance with minimal environmental impact. Whether it's heating your home in winter or cooling it in summer, Reclaim Heat Pumps provide consistent comfort while saving on energy costs and reducing reliance on traditional heating and cooling methods.</p>						
                      <p> </p>
                      <p>With Reclaim Heat Pumps, sustainability meets comfort without compromise. These systems are designed to seamlessly integrate into residential, commercial, and industrial settings, providing efficient heating and cooling solutions tailored to various needs. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className='product-usp-sectionDesk'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TeslaDeskSwiper2} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='product-usp-sectionMob'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} alt='MobileSwiper1' />
          </SwiperSlide>
          <SwiperSlide>
          <img  src={TeslaMobSwiper2} alt='MobileSwiper2' />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='product-specification'>
        <h2>Reclaim Specification</h2>
        <div className='specification-parent'>
          <div className='specification-child'>
            <h3>Power</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Reclaim Energy heat pump size</h5>
                <p>160 Litre, 250 Litre, 315 Litre, 400 Litre</p>
              </div>
              <div className='spec-card'>
                <h5>Heat output at 32.6°C ambient / 21.1°C cold water inlet</h5>
                <p>5.24kW</p>
              </div>
              <div className='spec-card'>
                <h5>Electric input at 32.6°C ambient / 21.1°C cold water inlet</h5>
                <p>0.87kW</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Features</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>COP at 32.6°C ambient / 21.1°C cold water inlet</h5>
                <p>6.02</p>
              </div>
              <div className='spec-card'>
                <h5>Power input (MAX)  </h5>
                <p>2.3kW</p>
              </div>
              <div className='spec-card'>
                <h5>Current input (MAX)</h5>
                <p>10A </p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Warranty</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Duration</h5>
                <p>6 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReclaimHeatpump