import { Fragment, useEffect, useState } from "react";
import 'swiper/css';
import Landing from "../my-components/Landing/Landing";
import Slider from "../my-components/Slider/Slide";
import USP from "../my-components/USP/USP";
import Packages from "../my-components/Packages/Packages";
import InfoContainer from "../my-components/InfoContainer/InfoContainer"
import HomeCard from "../my-components/HomeCard/HomeCard"


import { TabTitle } from "../utils/General";
import OurEcoSystem from "../my-components/OurEcoSystem/OurEcoSystem";
import TrustedCertified from "../my-components/TrustedCertified/TrustedCertified";
import GoogleReviews from "../my-components/GoogleReviews/GoogleReviews";


const Home = () => {

    TabTitle("Home");

    return (
        <Fragment>
            <Slider />
            <OurEcoSystem />
            <USP />
            <Landing />
            {/* <OurProcess /> */}
            <HomeCard />
            <Packages />
            <TrustedCertified />
            <GoogleReviews />
            {/* <FeaturedProducts /> */}
            <InfoContainer />

        </Fragment>
    );
}

export default Home;