import React from 'react';
import FeaturedProducts from '../../my-components/FeaturedProducts/FeaturedProducts';
import banner1 from '../../assets/homepage/iStore_heatpump.png';

const iStore = () => {
    const slides = [
        {
            imageUrl: banner1,
            title: 'iStore  - Heat Pump',
            description: "Offering premium efficiency and performance, the iStore 180 L - Air to Energy is the perfect choice for smaller homes that want the best in hot water all year round, powered by the sun's rays. The iStore consumes 75% - 85% less energy than conventional hot water systems. External wrap around heating coil provides maximum thermal energy transfer.",
            link: '/products/iStore-heatpump'
        }
    ];

    return (
        <div>
            <FeaturedProducts customSlides={slides} animation={false} />
        </div>
    )
}

export default iStore