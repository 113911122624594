import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './TeslaSolar.css';
import TeslaPDPDesk from '../../assets/productpages/Jinko_solar_panel.jpg';
import TeslaPDPMob from '../../assets/productpages/Jinko_solar_panel.jpg';
import TeslaMobSwiper1 from '../../assets/productpages/Jinko_solar_panel.jpg';
import TeslaMobSwiper2 from '../../assets/productpages/Jinko_solar_panel.jpg';
import TeslaDeskSwiper1 from '../../assets/productpages/powerwall2img1.jpg'
import TeslaDeskSwiper2 from '../../assets/productpages/powerwall2img2.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade, Navigation, Pagination } from 'swiper/modules';

import teslaPowerwall from '../../assets/productpages/Jinko_solar_panel.jpg';

const JinkoSolar = () => {
  return (
    <div className='product-page-super-parent'>
      <div className="custom-banner-section carousel">
        <div className="list">
          <div className="item" >
          {/* <video autoPlay loop muted controls className='product-main-banner'>
            <source src={teslabanner} type="video/mp4"/>
          </video> */}
          <div className='DesktopBannerTesla'>
            <img src={TeslaPDPDesk} alt='DesktopBanner' width={1920} height={1080}/>
          </div>
          <div className='MobileBannerTesla'>
            <img src={TeslaPDPMob} alt='MobileBanner' width={720} height={1280}/>
          </div>
            <div className="content MobBannerContent">
              <div className="title montserrat-bold">Jinko Tiger Neo</div>
              <div className="des montserrat-regular">The Jinko Tiger Neo is a high-efficiency solar panel series manufactured by Jinko Solar, one of the leading solar panel manufacturers globally. The Tiger Neo series is designed with advanced technology to offer superior performance, durability, and efficiency, making it an ideal choice for residential, commercial, and utility-scale solar power projects.</div>
              <div class="buttons">
                <Link to='/get-quote'>
                  <button className='lato-bold'>Get Started</button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-brand'>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-e4817c9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e4817c9" data-element_type="section">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b45e63c" data-id="b45e63c" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-9154ea2 elementor-widget elementor-widget-image" data-id="9154ea2" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container DeskTopTesla">
                    <img src={TeslaMobSwiper1} />
                  </div>
                  <div class="elementor-widget-container MobileTesla">
                    <img src={teslaPowerwall} />
                  </div>
                </div>
              </div>
              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4558e86" data-id="4558e86" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6b5e14b elementor-widget elementor-widget-heading" data-id="6b5e14b" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Powering Tomorrow with Advanced Solar Efficiency</h2>		</div>
                  </div>
                  <div class="elementor-element elementor-element-3e6203e elementor-widget elementor-widget-text-editor" data-id="3e6203e" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>The Jinko Tiger Neo series represents a leap forward in solar technology, setting new benchmarks for efficiency and performance. Designed with cutting-edge N-type monocrystalline silicon cells, these panels offer superior conversion rates, transforming more sunlight into usable electricity and maximizing energy output. Whether for residential rooftops, commercial installations, or expansive utility-scale projects, the Tiger Neo series delivers consistent and reliable power generation, even under challenging environmental conditions.</p>						
                      <p> </p>
                      <p>Enhanced with Multi-Busbar (MBB) technology and a half-cut cell design, the Jinko Tiger Neo series reduces resistance losses and improves shade tolerance, ensuring optimal performance and durability. Many models also feature bifacial capability, capturing sunlight from both sides of the panel to significantly boost energy yield. This innovative approach not only improves efficiency but also enhances the return on investment by generating more electricity over the panel's lifespan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <div className='product-usp-sectionDesk'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TeslaDeskSwiper2} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='product-usp-sectionMob'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} alt='MobileSwiper1' />
          </SwiperSlide>
          <SwiperSlide>
          <img  src={TeslaMobSwiper2} alt='MobileSwiper2' />
          </SwiperSlide>
        </Swiper>
      </div> */}
      <div className='product-specification'>
        <h2>Jinko Specification</h2>
        <div className='specification-parent'>
          <div className='specification-child'>
            <h3>Power</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Watt</h5>
                <p>440</p>
              </div>
              <div className='spec-card'>
                <h5>Module efficiency</h5>
                <p>22,02%</p>
              </div>
              <div className='spec-card'>
                <h5>Cell</h5>
                <p>Half-cut</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Features</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Number of cells</h5>
                <p>108 (54)</p>
              </div>
              <div className='spec-card'>
                <h5>Module type</h5>
                <p>Monofacial Framed</p>
              </div>
              <div className='spec-card'>
                <h5>Glass Type</h5>
                <p>Single Glass</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Warranty</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Duration</h5>
                <p>25 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JinkoSolar