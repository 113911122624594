import React from "react";
import './ComingSoon.css'
import { Link } from "react-router-dom";
import banner1 from '../../assets/homepage/solarpanel.jpg';

const ComingSoon = () =>{
    return (
        
	<section className="hero is-transparent is-fullheight comingSoonSection " style={{  background: `linear-gradient(rgba(34, 41, 65, 0.5), rgba(46, 55, 86, 0.5)),url(${banner1})`}}>
		
    <div className="hero-body mb-1">
        <div className="container has-text-centered content">
            <h1 className="title">
                <span className="fade fadeOne">COMING</span>
                <span className="fade fadeTwo">SOON</span>
            </h1>
            <p className=" subtitle fade fadeOne">We are excited to announce that we will be launching soon and can't wait to share our new platform with you.</p>
        
        </div>
    </div>
    <div className="hero-foot">
        <div className="columns has-text-centered">
            <div className="column">
                <div className="container">
                  <Link to="/" className="btn backToHomeBtn mt-1" data-aos="fade-up">
                        <em className="icon ni ni-arrow-left"></em>
                        <span>Back To Home</span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</section>

    );
}
export default ComingSoon;