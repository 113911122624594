import React from "react";
import ComingSoon from "../my-components/ComingSoon/ComingSoon";
import { TabTitle } from "../utils/General";

const ComingSoonPage = () =>{
    TabTitle("Coming Soon");
    return (
      <ComingSoon />

    );
}
export default ComingSoonPage;