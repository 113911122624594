import React, { useRef, useState } from 'react';
import './ContactForm.css'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal'
import banner1 from '../../assets/homepage/bg-image-1.jpg';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const validatePhoneNumber = (phoneNumber) => {
  // Regular expression to match Australian phone numbers
  const regex = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;
  return regex.test(phoneNumber);
};


const ContactForm = () => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    // Custom validation for phone number
    const phoneNumberInput = form.querySelector('#inputPhone4');
    const phoneNumberIsValid = validatePhoneNumber(phoneNumberInput.value);
    phoneNumberInput.setCustomValidity(phoneNumberIsValid ? '' : 'Please enter a valid number.');


    const isValid = form.checkValidity() && phoneNumberIsValid;

    if (isValid) {
      const formDataObj = {};
      Array.from(form.elements).forEach((element) => {
        if (element.name) {
          formDataObj[element.name] = element.value;
        }
      });

      setFormData(formDataObj);
      setShowModal(true);
      console.log(formDataObj);


      let formData = await fetch("https://script.google.com/macros/s/AKfycbz9Lc7RMcMEi03NFIVY3AFX3YEmUmVBmgtinPr8pvsVjdJKqurFSFhe9dSLSTAWyTNL/exec?action=qouteQueryCreated", {
        method: "POST",
        body: JSON.stringify({
          "name": formDataObj.FirstName,
          "email": formDataObj.Email,
          "phone": formDataObj.Phone,
          "message": formDataObj.Address
        }),
        redirect: "follow"
      });
      let formRes = await formData.text();
      setShowModal(false);

      form.reset();
      setValidated(false); // Reset form validation state
    } else {
      event.stopPropagation();
      setValidated(true);
    }
  }

  // Function to handle input change and reset custom validity message
  const handleInputChange = (event) => {
    const input = event.target;
    input.setCustomValidity('');
  };
  const handleCloseModal = () => {
    setShowModal(false);

  };

  return (
    <div className=" contactFormSection" style={{ background: `linear-gradient(rgba(34, 41, 65, 0.5), rgba(46, 55, 86, 0.5)),url(${banner1})` }}>

      <section id="formHolder">

        <div className="row">


          <div className="col-sm-6 brand" data-aos="fade-right">
            <textContainer>
              <div className="heading">
                <span className='montserrat-regular'>GET IN TOUCH</span>
                <h2 className='montserrat-bold'>Contact Us Now!</h2>

              </div>
              <hr />
              <div className="detailContainer">
                <span className='montserrat-bold'>Head Office</span>
                <p className='montserrat-regular'>Tower 1, Level 8/1341 Dandenong Rd, Chadstone VIC 3148, Australia</p>

                <span className='montserrat-bold'>Email Support</span><br />
                <a href="mailto:info@rsolar.com.au" target="_blank" className='montserrat-regular'>info@rsolar.com.au</a>
                <br />
                <span className='montserrat-bold'>Let's Talk</span><br />
                <a className='montserrat-regular' href="tel:+61429342406">Phone :  (+61) 429 342 406 </a>
              </div>
              <hr />

              <div className="followContainer">

                <h3 className='montserrat-regular'>Follow Us On:</h3>
                <ul className='ul-footer-common-parent icons social_links'>

                  <a href="https://www.facebook.com/Rsolar.au" target="_blank">
                    <li className="social__link help__link fb combo">

                      <FacebookIcon />

                    </li>
                  </a>
                  <a href="https://twitter.com/RSolar82290" target="_blank">
                    <li className="social__link help__link tw combo">
                      <TwitterIcon />
                    </li>
                  </a>
                  <a href="/" target="_blank">
                    <li className="social__link help__link ig combo">
                      <InstagramIcon />
                    </li>
                  </a>
                  <a href="http://www.linkedin.com/in/r-solar-32597328b" target="_blank">
                    <li className="social__link help__link tw combo ">
                      <LinkedInIcon />
                    </li>
                  </a>
                </ul>
              </div>
            </textContainer>
          </div>


          <div className="col-sm-6 form" data-aos="fade-left">
            <div className="form-peice">
              <h1 className='montserrat-bold'>Have Questions ?</h1>
              <p className='montserrat-regular'>Fill out the form below and a member of our team will get back to you in the next 24-48 hours to answer any questions.</p>
              <div className='form-container'>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="g-4">
                    <Col md="12">
                      <Form.Group controlId="validationCustom01">
                        <Form.Label className='lato-bold'>Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="Name"
                          placeholder="Name"
                        />

                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Form.Group controlId="validationCustomUsername">
                        <Form.Label className='lato-bold'>Email</Form.Label>
                        <InputGroup hasValidation>
                          <InputGroup.Text className='lato-bold' id="inputGroupPrepend">@</InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Email"
                            name="Email"
                            aria-describedby="inputGroupPrepend"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter valid Email.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group controlId="inputPhone4">
                        <Form.Label className='lato-bold'>Phone</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control type="tel" name="Phone" required onChange={handleInputChange} />
                          <Form.Control.Feedback type="invalid"  >
                            Please enter a valid Number.
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group controlId="ControlTextarea1">
                        <Form.Label className='lato-bold'>Message</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control as="textarea" name="message" rows={3} required />
                        </div>
                      </Form.Group>
                    </Col>



                    <Col sm="12">
                      <Button type="submit" className='lato-bold'>Submit</Button>
                    </Col>
                  </Row>
                </Form>

                {/* Modal for thank you message */}
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Your form has been submitted successfully.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>

        </div>

      </section>

    </div>


  );
}
export default ContactForm;