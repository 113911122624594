import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './TeslaSolar.css';
import TeslaPDPDesk from '../../assets/productpages/BYD.jpg';
import TeslaPDPMob from '../../assets/productpages/BYD.jpg';
import TeslaMobSwiper1 from '../../assets/productpages/BYD.jpg';
import TeslaMobSwiper2 from '../../assets/productpages/powerwall2img2mob.jpg'
import TeslaDeskSwiper1 from '../../assets/productpages/powerwall2img1.jpg'
import TeslaDeskSwiper2 from '../../assets/productpages/powerwall2img2.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade, Navigation, Pagination } from 'swiper/modules';

import teslaPowerwall from '../../assets/productpages/BYD.jpg';

const BydBattery = () => {
  return (
    <div className='product-page-super-parent'>
      <div className="custom-banner-section carousel">
        <div className="list">
          <div className="item" >
          {/* <video autoPlay loop muted controls className='product-main-banner'>
            <source src={teslabanner} type="video/mp4"/>
          </video> */}
          <div className='DesktopBannerTesla'>
            <img src={TeslaPDPDesk} alt='DesktopBanner' width={1920} height={1080}/>
          </div>
          <div className='MobileBannerTesla'>
            <img src={TeslaPDPMob} alt='MobileBanner' width={720} height={1280}/>
          </div>
            <div className="content MobBannerContent">
              <div className="title montserrat-bold">BYD Batteries</div>
              <div className="des montserrat-regular">BYD battery systems are advanced energy storage solutions designed for residential, commercial, and utility-scale applications. They utilize lithium iron phosphate (LiFePO4) chemistry, known for its high safety, long cycle life, and stable performance. BYD batteries integrate smart energy management features and can be used for various purposes, including backup power during outages, peak shaving, load shifting, and grid stabilization. With modular designs, BYD batteries offer scalability to meet different energy storage needs and can be easily integrated with solar PV systems or standalone applications.</div>
              <div class="buttons">
                <Link to='/get-quote'>
                  <button className='lato-bold'>Get Started</button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-brand'>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-e4817c9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e4817c9" data-element_type="section">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b45e63c" data-id="b45e63c" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-9154ea2 elementor-widget elementor-widget-image" data-id="9154ea2" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container DeskTopTesla">
                    <img src={TeslaMobSwiper1} />
                  </div>
                  <div class="elementor-widget-container MobileTesla">
                    <img src={teslaPowerwall} />
                  </div>
                </div>
              </div>
              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4558e86" data-id="4558e86" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6b5e14b elementor-widget elementor-widget-heading" data-id="6b5e14b" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Powering Tomorrow, Today: BYD Battery Solutions</h2>		</div>
                  </div>
                  <div class="elementor-element elementor-element-3e6203e elementor-widget elementor-widget-text-editor" data-id="3e6203e" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>BYD Battery Solutions offer cutting-edge energy storage technology for a sustainable future. With their advanced lithium iron phosphate chemistry, BYD batteries ensure reliable power storage with high safety standards and long-lasting performance. Whether for residential, commercial, or utility-scale applications, BYD batteries provide flexible energy management solutions that empower users to take control of their energy usage, reduce costs, and contribute to a greener environment.</p>						
                      <p> </p>
                      <p>BYD Battery Solutions represent the forefront of innovation in energy storage, offering a seamless integration of renewable energy sources into our daily lives. With modular designs and smart energy management capabilities, BYD batteries empower homes, businesses, and communities to harness the power of the sun and wind, reducing reliance on fossil fuels and paving the way for a cleaner energy future. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <div className='product-usp-sectionDesk'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TeslaDeskSwiper2} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='product-usp-sectionMob'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} alt='MobileSwiper1' />
          </SwiperSlide>
          <SwiperSlide>
          <img  src={TeslaMobSwiper2} alt='MobileSwiper2' />
          </SwiperSlide>
        </Swiper>
      </div> */}
      <div className='product-specification'>
        <h2>BYD Specification</h2>
        <div className='specification-parent'>
          <div className='specification-child'>
            <h3>Power</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Total Battery Capacity</h5>
                <p>7.68 kWh</p>
              </div>
              <div className='spec-card'>
                <h5>Battery Module</h5>
                <p>HVS (2.56 kWh, 102.4 V, 38 kg)</p>
              </div>
              <div className='spec-card'>
                <h5>Nominal Voltage</h5>
                <p>307 V</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Features</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Max Output Current</h5>
                <p>25 A</p>
              </div>
              <div className='spec-card'>
                <h5>Operating Voltage </h5>
                <p>240~360 V</p>
              </div>
              <div className='spec-card'>
                <h5>Dimensions (H / W / D) </h5>
                <p>945 x 585 x 298 mm </p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Warranty</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Duration</h5>
                <p>10 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BydBattery