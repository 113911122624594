import React from 'react';
import './BlogCategorySection.css'
import Blog1 from '../../assets/homepage/blog_article_battery.jpg'
import Blog2 from '../../assets/homepage/solar_panel_home_blog.jpg'
import Blog3 from '../../assets/homepage/invertor_image_blog.jpg'
import { Link } from 'react-router-dom';
import Calender from '../../assets/homepage/Calender.jpg'

const BlogsDetails = [
  {
    date: "March 19, 2024",
    description: "Storing Excess Energy Using Solar Batteries",
    image: Blog1,
    linkto: '/blogs/solar-invertors-explained'
  },
  {
    date: "March 24, 2024",
    description: "Choosing the Right Solar Panels for Your Home",
    image: Blog2,
    linkto: '/blogs/choosing-right-solar-panel'
  },
  {
    date: "April 15, 2024",
    description: "Solar Inverters Explained: A Key Component of Your Home Solar Setup",
    image: Blog3,
    linkto: '/blogs/solar-invertors-know-more'
  }
];

const BlogCategorySection = () => {
  return (
    <section className="BlogMain">
      <div>
        <h2 className='BlogMainHeading'>Solar Blogs Category</h2>
        <div className="BlogsContainer">
          {BlogsDetails.map((blog, index) => (
            <div key={index} className="Blogs">
              <div className="Blog-image">
                <img src={blog.image} alt={blog.name} />
                <div className="BlogContent">
                  <div className='Blog-content-Inner'>
                    <div className='blogDescription'>{blog.description}</div>
                    <div className='bottomBlogContent'>
                      <div className='BlogDateContainer'>
                        <div className='BlogCalenderImageContainer'>
                          <img src={Calender} alt={blog.name} />
                        </div>
                        <div className='blogDate'>{blog.date}</div>
                      </div>
                      <Link to={blog.linkto} className='blogViewMore'>View Article</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogCategorySection;
