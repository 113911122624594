import './NavBrand.css'
import { Link } from 'react-router-dom';
import LOGO from '../../../assets/homepage/WEB-LOGO.png'

const NavBrand = () => {
    return ( 
        <div href="#home" className='navbrand__container'>
           <h1 className='navbrand'>
               <Link to="/"><img className="logo__image" style={{height:'50px'}} src={LOGO} alt="R Solar" /></Link>
            </h1>
        </div>
     );
}
 
export default NavBrand;