import React from "react";
import ImageWithText from "../my-components/ImageWithText/ImageWithText";
import ImageText from "../my-components/ImageText/ImageText";
import { TabTitle } from "../utils/General";
import { Fragment } from "react";


const About = () =>{
    TabTitle("About");

     return (
        <Fragment>
            <ImageWithText/>
            {/* <OurProcess /> */}
            <ImageText />
         
        </Fragment>
     );
}

export default About;