import React from 'react';
import FeaturedProducts from '../../my-components/FeaturedProducts/FeaturedProducts';
import banner1 from '../../assets/homepage/sungrow_solar_inverter.webp';
import banner2 from '../../assets/homepage/sungrow_battery.png';

const Sungrow = () => {
    const slides = [
        {
            imageUrl: banner1,
            title: 'Sungrow - Solar Inverter',
            description: "Sungrow was established from the ground up as a PV inverter manufacturer. Their SG series are both transformerless and lightweight string inverters with Multiple Power Point Tracking. Sungrow inverters achieve higher yields with fantastic efficiency and feature surge protection and PV isolators for consumer safety.",
            link: '/products/sungrow-invertor'
        },
        {
            imageUrl: banner2,
            title: 'Sungrow - Solar Battery',
            description: "Regarding battery performance and reliability, Sungrow strives to deliver high-quality and reliable energy storage solutions. Sungrow utilizes advanced battery chemistries, typically Lithium-ion, in their energy storage systems. These batteries offer high energy density, long cycle life, and excellent performance, ensuring reliability and longevity. Among their range of products, the Sungrow 9.6kWh battery stands out as one of their most popular offerings.",
            link: '/coming-soon'
        }
    ];

    return (
        <div>
            <FeaturedProducts customSlides={slides} animation={false} />
        </div>
    )
}

export default Sungrow