import React from 'react'
import './ArticlePage.css'
import ArticleImage from '../../assets/homepage/blog_article_battery.jpg'
const article = {
    image: ArticleImage,
    ImageDescription: "Solar power plays a crucial role in advancing towards a sustainable future, bolstering grid resilience, and reducing utility expenses. Integrating solar panels with battery storage technology ensures energy availability around the clock, offering numerous advantages. Instead of generating energy only to lose it after sunset, storing it for later use makes practical sense. Let's delve into why investing in solar battery storage is a wise decision.",
    heading1: "Storing Excess Energy Using Solar Batteries",
    description2: "Harnessing solar energy represents a pivotal step towards achieving sustainability, enhancing energy grid robustness, and cutting down on utility costs. By combining solar panels with advanced battery storage solutions, you ensure continuous access to energy regardless of the time of day, presenting a host of additional benefits. Rather than letting generated energy go to waste after sunset, storing it offers a proactive solution. Let's explore the compelling reasons why adopting solar battery storage is a savvy choice.",
    highlightMessage: "“Powering Tomorrow, Today: Harness Solar Energy, Store Smart!”",
    heading2: "Solar Battery Types",
    description4: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
}
export const ArticlePage = () => {
    return (
        <section className='ArticleMain'>
            <div className='ArticleContainer'>
                <div className='ArticleImageContainer'>
                    <img src={article.image} alt='articleImage' />
                </div>
                <div className='ArticleContent'>
                    <h1 style={{ color: '#000' }}>Storing Excess Energy Using Solar Batteries</h1>
                    <p>{article.ImageDescription}</p>
                    <h2>{article.heading1}</h2>
                    <p>{article.description2}</p>
                    <div className='ArticleHighlightMessage'>
                        <div>{article.highlightMessage}</div>
                    </div>
                    <h3>{article.heading2}</h3>
                    <p>Solar battery technology is continuously improving. There are two main types of batteries: AC batteries and DC batteries. Examples:</p>
                    <p style={{margin:0}}>1. Lead-acid batteries</p>
                    <ul>
                        <li>Reliable and cost-effective</li>
                        <li>Low energy density</li>
                    </ul>
                    <p style={{margin:0}}>2. Lithium-ion (Li) batteries</p>
                    <ul>
                        <li>High energy density</li>
                        <li>Smaller, lighter, and more efficient</li>
                    </ul>
                    <h3>What Happens to Surplus Solar Energy?</h3>
                    <p>Grid storage is when the excess energy generated by your solar panel system flows to the shared power grid operated by your utility company. When you contribute to grid storage your utility company may provide energy credits on your monthly bill via net metering. Net metering measures the electricity your home solar system sends to your utility and the electricity you use from your utility.</p>
                    <p>Onsite storage via solar batteries effectively captures solar energy and offers many benefits, including: </p>
                    <ul>
                        <li>Cost-savings:  Since solar power batteries store energy made by your solar panel system, they reduce the amount of electricity you’ll need to purchase from your local utility company. If your utility offers net metering, you may get further savings.</li>
                        <li>Energy Independence: Solar batteries allow homeowners to minimize their reliance on overburdened public-utility energy grids vulnerable to disruption.</li>
                        <li>Backup Power: Backup solar battery systems offer a continuous power supply during peak demand and utility outages. Solar batteries also make it possible to use the energy produced by your solar system to power your home or charge your electric vehicle outside of daylight hours.</li>
                        <li>Noiseless backup:  Unlike some generators, solar battery power systems won’t annoy you or your neighbors by creating noise pollution.</li>
                    </ul>
                    <h3>
                        How Much Do Solar Battery Storage Systems Cost?
                    </h3>
                    <p>Cost varies based on the manufacturer, battery type, storage capacity (measured in kilowatt-hours, kWh), lifespan, and installation complexity.

                        While solar storage systems can be a significant financial investment, they save money on energy costs during off-peak hours and in communities with favorable net metering policies.

                    </p>
                    <h3>Conclusion</h3>
                    <p>
                        In conclusion, embracing solar energy alongside efficient battery storage systems is not just a step towards sustainability and resilience—it's a leap towards a brighter, more cost-effective future. By capturing and storing energy from the sun, we not only reduce our reliance on traditional energy sources but also pave the way for a more reliable and environmentally friendly energy grid. Investing in solar battery technology is not only practical but essential for shaping a sustainable tomorrow.
                    </p>
                </div>

            </div>

        </section>
    )
}
