import React from 'react'
import FeaturedProducts from '../../my-components/FeaturedProducts/FeaturedProducts';
import banner1 from '../../assets/homepage/TrinaPanels.png';

const Trina = () => {
    const slides = [
        {
            imageUrl: banner1,
            title: 'Trina ',
            description: "The upgraded Vertex S+ offers outstanding product performance combined with innovative, design-oriented aesthetics thanks to the transparent double-glazed concept. The bifacial double glazing technology improves durability, fire resistance, performance and recyclability. Combined with the new N-type i-TOPCon cell technology, this is the next generation of solar modules for residential and commercial rooftop installations.",
            link: '/products/trina-solar'
        }
    ];

    return (
        <div>
            <FeaturedProducts customSlides={slides} animation={false}/>
        </div>
    )
}

export default Trina