import React from "react";
import './ImageText.css';
import { Link } from "react-router-dom";
import bannerImage from '../../assets/homepage/usp2.jpg';

const ImageText = () => {

      return (
        <section className="about-image-section">
        <div className="container">
        <span className="title" data-aos="fade-right">ALL ROADS LEAD TO SOLAR!</span>
            <div className="row" >                
                <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2" data-aos="fade-left">

                    <div className="inner-column">
                        <div className="about-sec-title">
                           
                            <h2>Working for a Brighter & More Clean Future</h2>
                        </div>
                        <div className="text">Solar Energy revolutionizes residential spaces through strategic installations of rooftop, ground-mounted, and carport solar systems, providing an abundant and eco-friendly power source that significantly diminishes reliance on conventional grid electricity. This leads to substantial reductions in energy bills for customers. Renowned for unparalleled customer satisfaction, the accomplished team at R SOLAR excels not only in installation but also in pioneering innovative solar technology solutions tailored to individual needs.</div>
                        <div className="text">Committed to advancing solar technology, R SOLAR contributes to a greener future by seamlessly integrating cutting-edge solutions. This empowers homeowners to harness renewable energy, promoting environmental stewardship while simultaneously reducing utility expenses. R SOLAR's unwavering dedication to pushing the boundaries of solar technology underscores their relentless pursuit of excellence, reshaping the solar energy landscape. Through their innovative endeavors, R SOLAR stands as a trailblazer, spearheading the transition towards cleaner and more efficient power alternatives and embodying a commitment to a sustainable future.</div>
                        <div className="btn-box">
                        <Link to="/get-quote">ASK ANY QUESTION</Link>
                        </div>
                    </div>
                </div>

                
                <div className="image-column col-lg-6 col-md-12 col-sm-12 mt-1" data-aos="fade-right" >
                    <div className="inner-column" >
                      <figure className="image-1" ><img title="solar panel" src={bannerImage} alt="" /></figure>
                     
                    </div>
                </div>
              
            </div>
        
            </div>
            </section>
      );
}
export default ImageText;