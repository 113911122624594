import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './Packages.css';
import banner1 from '../../assets/homepage/Banner.png';
import banner2 from '../../assets/homepage/banner2.png';
import banner3 from '../../assets/homepage/banner3.png';
import TrinaPanels from '../../assets/homepage/TrinaPanels.png';
import sungrowSolarInverter from '../../assets/homepage/sungrow_solar_inverter.png';
import sungrowBattery from '../../assets/homepage/sungrow_battery.png';
import reclaimHeatpump1 from '../../assets/homepage/reclaim_heatpump1.png';
import zappiEvCharger from '../../assets/homepage/zappi_ev_charger.jpg';
import recWebsiteTp5 from '../../assets/homepage/rec_website_tp5.jpg';
import froniusSolarInvertor from '../../assets/homepage/froniusSolarInvertor.png';
import teslaBattery from '../../assets/homepage/tesla_battery.png';
import EnphaseMicroinverters from '../../assets/homepage/EnphaseMicroinverters.webp';
import { Link } from "react-router-dom"

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';

const slides = [
  {
    imageUrl: banner1,
    title: 'All Electric Deal',
    description: "<li class='lato-regular'>40 x Trina Vertex S+ </li><li>1 x Sungrow Hybrid Inverter</li><li>1 x Sungrow 16 kWh Battery</li><li>1 x Reclaim Heat Pump</li><li>1 x Zappi EV Charger</li>",
    buttonText: 'Get Price',
    images: [
      TrinaPanels,
      sungrowSolarInverter,
      sungrowBattery,
      reclaimHeatpump1,
      zappiEvCharger
    ]

  },
  {
    imageUrl: banner2,
    title: 'High End Package',
    description: "<li class='lato-regular'>24 x REC Alpha Pure R</li><li>1 x Fronius Inverter</li><li>1 x Tesla Battery </li>",
    buttonText: 'Get Price',
    images: [
      recWebsiteTp5,
      froniusSolarInvertor,
      teslaBattery
    ]
  },
  {
    imageUrl: banner3,
    title: 'Battery Starter Combo',
    description: "<li class='lato-regular'>24 x Trina Vertex S+</li><li>1 x Sungrow Hybrid Inverter</li><li>1 x Sungrow 9.6 kWh Battery</li>",
    buttonText: 'Get Price',
    images: [
      TrinaPanels,
      sungrowSolarInverter,
      sungrowBattery
    ]
  },
  {
    imageUrl: banner2,
    title: 'ShadeSmart Solar Solution Package',
    description: "<li class='lato-regular'>24 x Trina</li><li>24 x Enphase Microinverters</li>",
    buttonText: 'Get Price',
    images: [
      TrinaPanels,
      EnphaseMicroinverters
    ]
  },
  {
    imageUrl: banner1,
    title: 'Solar Only',
    description: "<li class='lato-regular'>15 x Trina Vertex S+</li><li>1 x Sungrow Inverter</li>",
    buttonText: 'Get Price',
    images: [
      TrinaPanels,
      sungrowSolarInverter
    ]
  }
];

const Packages = () => {
  return (
    <section className='our-packages-section'>
      <h2 className='floating' data-aos="fade-right" >Packages</h2>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper package-swiper"
        data-aos="fade-up"
      >

        {slides.map((slide, index) => (
          <SwiperSlide className="list" >
            <div key={index} className="item" >
              <Swiper
                slidesPerView={1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper package-swiper"
              >
                {console.log('slide?.images', slide?.images)}
                {slide?.images.map((slideImage, ind) => {
                  return (<>
                    <SwiperSlide className="list1 packagesSwiperInside">
                      <img src={slideImage} alt={`Slide ${ind}`} />
                    </SwiperSlide>
                  </>)
                })}
              </Swiper>
              <div className="content">
                <div className="title montserrat-regular">{slide.title}</div>
                <ul className="des checkmark" dangerouslySetInnerHTML={{ __html: slide.description }}></ul>
                <div class="buttons">
                  <Link to='/get-quote'>
                    <button className='lato-bold'>{slide.buttonText}</button>
                  </Link>

                </div>
              </div>

            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
export default Packages;