import { Fragment, useEffect, useState } from "react";

import 'swiper/css';
import Form from "../Form/Form";
import './GetQuote.css';
import banner1 from '../../assets/homepage/panel.jpeg';
import banner from '../../assets/homepage/bg-image-1.jpg';





const GetQuote = () => {
  

    return ( 
       <section className="getQuoteSection" style={{background: `linear-gradient(rgba(34, 41, 65, 0.5), rgba(46, 55, 86, 1)),url(${banner})`}}>
        <main data-aos="fade-up">
        <div class="left-side" style={{ backgroundImage:`url(${banner})`}} ></div>
       
       <div class="right-side" >
         < Form/>
       </div>
       
     </main>
     </section> 
        
    );
}
 
export default GetQuote;