import React, { useRef, useState } from 'react';
import './Form.css'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal'

const validatePhoneNumber = (phoneNumber) => {
    // Regular expression to match Australian phone numbers
    const regex = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;
    return regex.test(phoneNumber);
};
const validateZipCode = (zipCode) => {
    // Regular expression to match Australian zip codes
    const regex = /^[0-9]{4}$/;
    return regex.test(zipCode);
};

const validateCity = (city) => {
    const australianCities = [
        "Sydney",
        "Melbourne",
        "Brisbane",
        "Perth",
        "Adelaide",
        "Gold Coast",
        "Newcastle",
        "Canberra",
        "Sunshine Coast",
        "Wollongong",
        "Hobart",
        "Geelong",
        "Townsville",
        "Cairns",
        "Darwin",
        "Toowoomba",
        "Ballarat",
        "Bendigo",
        "Albury-Wodonga",
        "Launceston",
        "Mackay",
        "Rockhampton",
        "Bundaberg",
        "Bunbury",
        "Hervey Bay",
        "Mildura",
        "Coffs Harbour",
        "Wagga Wagga",
        "Shepparton",
        "Port Macquarie",
        "Gladstone",
        "Tamworth",
        "Traralgon",
        "Mount Gambier",
        "Orange",
        "Dubbo",
        "Geraldton",
        "Nowra-Bomaderry",
        "Bathurst",
        "Warrnambool",
        "Kalgoorlie-Boulder",
        "Lismore",
        "Albany",
        "Devonport",
        "Mount Isa",
        "Maryborough",
        "Grafton",
        "Alice Springs",
        "Whyalla",
        "Mount Barker",
        "Armidale",
        "Murray Bridge",
        "Griffith",
        "Broken Hill",
        "Victor Harbor",
        "Kingaroy",
        "Bongaree",
        "Goulburn",
        "Katherine",
        "Port Pirie",
        "Singleton",
        "Bairnsdale",
        "Taree",
        "Bowen",
        "Muswellbrook",
        "Yeppoon",
        "Horsham",
        "Warwick",
        "Biloela",
        "Busselton",
        "Tweed Heads",
        "Queanbeyan",
        "Esperance",
        "Moranbah",
        "Port Lincoln",
        "Emerald",
        "Port Augusta",
        "Forster-Tuncurry",
        "Sale",
        "Broome",
        "Port Hedland",
        "Northam",
        "Carnarvon",
        "Leonora",
        "Collie",
        "Narrogin",
        "Merredin",
        "Cowra",
        "Parkes",
        "Kingston Beach",
        "Newman",
        "Esperance",
        "Bacchus Marsh",
        "Norseman",
        "Exmouth",
        "Meekatharra",
        "Kojonup",
        "Tom Price",
        "Merimbula",
        "Longreach",
        "Stawell",
        "Cloncurry",
        "Winton",
        "Ingham",
        "Yulara",
        "Kununurra",
        "Ouyen",
        "Roma",
        "Charleville",
        "Laverton",
        "Winton",
        "Deniliquin",
        "Quilpie",
        "Thargomindah",
        "Windorah",
        "Bedourie",
        "Kempsey",
        "Ilfracombe",
        "Katanning",
        "Bourke",
        "Normanton",
        "Birdsville",
        "Ravensthorpe",
        "Three Springs",
        "Hughenden",
        "Tennant Creek",
        "Collarenebri",
        "Ulladulla",
        "Walgett",
        "Dysart",
        "Gayndah",
        "Monto",
        "Morven",
        "Capella",
        "Tumbarumba",
        "Wangaratta",
        "Theodore",
        "Gingin",
        "Tully",
        "Camooweal",
        "Chinchilla",
        "Alpha",
        "Charleville",
        "St George",
        "Ayr",
        "Ingham",
        "Roma",
        "Inglewood",
        "Richmond",
        "Kilcoy",
        "Clermont",
        "Boulia",
        "Blackall",
        "Collinsville",
        "Stanthorpe",
        "Jundah",
        "Dajarra",
        "Palm Island",
        "Eromanga",
        "Surat",
        "Thargomindah",
        "Eidsvold",
        "Croydon",
        "Betoota",
        "Bedourie",
        "Cooktown",
        "Doomadgee",
        "Kowanyama",
        "Mornington Island",
        "Pormpuraaw",
        "Adavale",
        "Burketown",
        "Normanton",
        "Karumba",
        "Chillagoe",
        "Archer River",
        "Weipa",
        "Lawn Hill",
        "Gununa",
        "Ernabella",
        "Indulkana",
        "Umuwa",
        "Marla",
        "Mintabie",
        "Amata",
        "Fregon",
        "Iwantja",
        "Kaltjiti",
        "Kanpi",
        "Kulgera",
        "Mimili",
        "Mintabie",
        "Pipalyatjara",
        "Tjukurla",
        "Watarru",
        "Yalata",
        "Yulara",
        "Willare",
        "Andamooka",
        "Ceduna",
        "Coen",
        "Cook",
        "Laverton",
        "Leigh Creek",
        "Lajamanu",
        "Marble Bar",
        "Oodnadatta",
        "Pimba",
        "Wilcannia",
        "Yalgoo",
        "Lake Grace",
        "Meekatharra",
        "Norseman",
        "Sandstone",
        "Leinster",
        "Halls Creek",
        "Leonora",
        "Wiluna",
        "Wittenoom",
        "Ravensthorpe",
        "Warburton",
        "Woomera",
        "Yuendumu",
        "Coonamble",
        "Tibooburra",
        "White Cliffs",
        "Hermannsburg",
        "Lajamanu",
        "Papunya",
        "Tennant Creek",
        "Baralaba",
        "Mount Magnet",
        "Wiluna",
        "Sandstone",
        "Kintore",
        "Irrunytju",
        "Ernabella",
        "Amata",
        "Pipalyatjara",
        "Fregon",
        "Kulgera",
        "Kanpi",
        "Kaltjiti",
        "Mintabie",
        "Tjukurla",
        "Watarru",
        "Yalata",
        "Yulara",
        "Willare",
        "Ceduna",
        "Coen",
        "Cook",
        "Leigh Creek",
        "Lajamanu",
        "Marble Bar",
        "Oodnadatta",
        "Pimba",
        "Wilcannia",
        "Yalgoo",

    ].map(city => city.toLowerCase());


    const enteredCity = city.trim().toLowerCase();
    return australianCities.includes(enteredCity);
};

const FormOne = () => {
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});
    const [showModal, setShowModal] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        // Custom validation for phone number
        const phoneNumberInput = form.querySelector('#inputPhone4');
        const phoneNumberIsValid = validatePhoneNumber(phoneNumberInput.value);
        phoneNumberInput.setCustomValidity(phoneNumberIsValid ? '' : 'Please enter a valid Australian phone number.');

        // Custom validation for zip code
        // const zipCodeInput = form.querySelector('#validationCustom05');
        // const zipCodeIsValid = validateZipCode(zipCodeInput.value);
        // zipCodeInput.setCustomValidity(zipCodeIsValid ? '' : 'Please enter a valid Australian zip code.');

        // const cityInput = form.querySelector('#validationCustom03');
        // const cityIsValid = validateCity(cityInput.value);
        // cityInput.setCustomValidity(cityIsValid ? '' : 'Please enter a city from Australia.');

        // Check if all fields are valid
        const isValid = form.checkValidity() && phoneNumberIsValid;
        console.log('isValid', isValid)
        if (isValid) {
            const formDataObj = {};
            Array.from(form.elements).forEach((element) => {
                if (element.name) {
                    formDataObj[element.name] = element.value;
                }
            });
            setFormData(formDataObj);
            setShowModal(true);
            console.log(formDataObj);


            let formData = await fetch("https://script.google.com/macros/s/AKfycbz9Lc7RMcMEi03NFIVY3AFX3YEmUmVBmgtinPr8pvsVjdJKqurFSFhe9dSLSTAWyTNL/exec?action=qouteQueryCreated", {
                method: "POST",
                body: JSON.stringify({
                    "name": formDataObj.FirstName,
                    "email": formDataObj.Email,
                    "phone": formDataObj.Phone,
                    "address": formDataObj.Address,
                    "purchaseTimeFrame": formDataObj.TimeFrameOfPurchase,
                    "systemType": formDataObj.WhatTypeOfSystem,
                }),
                redirect: "follow"
            });
            let formRes = await formData.text();
            setShowModal(false);
            // Reset form fields
            form.reset();

            setValidated(false); // Reset form validation state
        } else {
            event.stopPropagation();
            setValidated(true);
        }


    };


    // Function to handle input change and reset custom validity message
    const handleInputChange = (event) => {
        const input = event.target;
        input.setCustomValidity('');
    };
    const handleCloseModal = () => {
        setShowModal(false);

    };

    return (
        <div className='form-container'>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <h2 className='montserrat-bold'>Request Your Quote</h2>
                <Row className="g-4">
                    <Col md="6">
                        <Form.Group controlId="validationCustom01">
                            <Form.Label className='lato-bold'>Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="FirstName"
                                placeholder="Full name"
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    {/* <Col md="6">
                        <Form.Group controlId="validationCustom02">
                            <Form.Label className='lato-bold'>Last name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="LastName"
                                placeholder="Last name"
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                    </Col> */}
                    <Col md="6">
                        <Form.Group controlId="validationCustomUsername">
                            <Form.Label className='lato-bold'>Email</Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    name="Email"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter valid Email.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="inputPhone4">
                            <Form.Label className='lato-bold'>Phone</Form.Label>
                            <div className="form-control-wrap">
                                <Form.Control type="tel" name="Phone" required onChange={handleInputChange} />
                                <Form.Control.Feedback type="invalid"  >
                                    Please enter a valid Australian phone number.
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="inputAddress">
                            <Form.Label className='lato-bold'>Address</Form.Label>
                            <div className="form-control-wrap">
                                <Form.Control type="text" name="Address" placeholder="1234 Main St" required />
                            </div>
                        </Form.Group>
                    </Col>
                    {/* <Col md="4">
                    <Form.Group controlId="validationCustom03">
                        <Form.Label className='lato-bold'>City</Form.Label>
                        <Form.Control type="text" name="City" placeholder="City" required onChange={handleInputChange}  />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid city.
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Col> */}
                    {/* <Col md="4">
                        <Form.Group controlId="validationCustom04">
                            <Form.Label className='lato-bold'>State</Form.Label>
                            <div className="form-control-wrap">
                                <Form.Select  required name="State">
                                    <option value="">Choose...</option>
                                    <option value="ACT">Australian Capital Territory</option>
                                    <option value="NSW">New South Wales</option>
                                    <option value="NT ">Northern Territory</option>
                                    <option value="QLD">Queensland</option>
                                    <option value="SA ">South Australia</option>
                                    <option value="TAS">Tasmania</option>
                                    <option value="VIC">Victoria</option>
                                    <option value="WA ">Western Australia</option>
                                </Form.Select>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid state.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col> */}
                    {/* <Col md="4">
                    <Form.Group controlId="validationCustom05">
                        <Form.Label className='lato-bold'>Zip</Form.Label>
                        <Form.Control type="text" name="Zip" placeholder="Zip" required  onChange={handleInputChange} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Australian zip code.
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col> */}
                    <Col md="6">
                        <Form.Group controlId="validationCustom06">
                            <Form.Label className='lato-bold'>Time Frame of Purchase?</Form.Label>
                            <div className="form-control-wrap">
                                <Form.Select required name="TimeFrameOfPurchase">
                                    <option value="Immediately">Immediately </option>
                                    <option value="Within 4 Weeks">Within 4 Weeks </option>
                                    <option value="Within 3 Months">Within 3 Months</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="validationCustom07">
                            <Form.Label className='lato-bold'>What Type of System?</Form.Label>
                            <div className="form-control-wrap">
                                <Form.Select required name="WhatTypeOfSystem">
                                    <option value="Mid – Range System">Mid – Range System</option>
                                    <option value="High - End System">High - End System</option>
                                    <option value="Any Preferred System">Any Preferred System</option>
                                </Form.Select>
                            </div>
                        </Form.Group>

                    </Col>
                    {/* <Col md="12">
                    <Form.Group controlId="validationCustom08">
                            <Form.Label className='lato-bold'>Upload File</Form.Label>
                            <div className="form-control-wrap">
                                
                            <Form.Control type="file" name="File" maxSize="268435456 " />
                           
                            </div>
                        </Form.Group>
                       
                    </Col> */}

                    <Col sm="12">
                        <Button type="submit" className='lato-bold'>Submit</Button>
                    </Col>
                </Row>
            </Form>

            {/* Modal for thank you message */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your form has been submitted successfully.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default FormOne;