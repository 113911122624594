import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import 'swiper/css';
import ContactForm from "../my-components/ContactForm/ContactForm";


import { TabTitle } from "../utils/General";


const Contact = () => {
   
    TabTitle("contact");


    return ( 
        <Fragment>
           <ContactForm />
        </Fragment>
    );
}
 
export default Contact;