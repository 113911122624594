import React from 'react'
import FeaturedProducts from '../../my-components/FeaturedProducts/FeaturedProducts';
import banner1 from '../../assets/homepage/tige2.png';

const Jinko = () => {
    const slides = [
        {
            imageUrl: banner1,
            title: 'Jinko ',
            description: "With the Tiger Neo 54HL4-V, JinkoSolar is launching a highly efficient and powerful solar module for private and commercial photovoltaic systems. With a rated output of up to 425 watts and an efficiency of up to 21.76%, the module demonstrates its outstanding performance even in diffuse light conditions, thus ensuring an excellent energy yield. In addition, its robust design and resistance to adverse weather conditions make it the ideal choice for anyone who values performance, quality and reliability.",
            link: '/products/jinko-solar'
        }
    ];

    return (
        <div>
            <FeaturedProducts customSlides={slides} animation={false}/>
        </div>
    )
}

export default Jinko