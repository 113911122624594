import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './FeaturedProducts.css';
import banner1 from '../../assets/homepage/tige2.png';
import banner2 from '../../assets/homepage/VertexSplus.png';
import banner3 from '../../assets/homepage/rec_website_tp5.jpg';
import banner4 from '../../assets/homepage/Alpha_Pure_R.png';
import banner5 from '../../assets/homepage/Alpha_Pure_RX.jpg';
import { Link } from "react-router-dom";
import ComingSoon from '../../routes/ComingSoon';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

const FeaturedProducts = (props) => {

  let slides = [];
  let { customSlides, animation } = props;
  if (customSlides?.length) {
    slides = customSlides;
  } else {
    slides = [
      {
        imageUrl: banner1,
        title: 'Jinko ',
        description: "With the Tiger Neo 54HL4-V, JinkoSolar is launching a highly efficient and powerful solar module for private and commercial photovoltaic systems. With a rated output of up to 425 watts and an efficiency of up to 21.76%, the module demonstrates its outstanding performance even in diffuse light conditions, thus ensuring an excellent energy yield. In addition, its robust design and resistance to adverse weather conditions make it the ideal choice for anyone who values performance, quality and reliability."

      },
      {
        imageUrl: banner2,
        title: 'Trina ',
        description: "The upgraded Vertex S+ offers outstanding product performance combined with innovative, design-oriented aesthetics thanks to the transparent double-glazed concept. The bifacial double glazing technology improves durability, fire resistance, performance and recyclability. Combined with the new N-type i-TOPCon cell technology, this is the next generation of solar modules for residential and commercial rooftop installations."

      },
      {
        imageUrl: banner3,
        title: 'REC ',
        description: "Based on the multiple award-winning REC TwinPeak technology, REC TwinPeak 5 panels bring heightened efficiency to REC's portfolio. Building on REC's long experience with half-cut cell technology, the REC TwinPeak 5 Series uses p-type monocrystalline cells, offering an even greater efficiency and power, making this Series suitable for all applications and system sizes, anywhere in the world! REC TwinPeak 5 features REC's innovative design with the high panel efficiency and power output of monocrystalline cells, enabling you to get more out of the space available – helping to lower your energy bills and shorten the payback time through increased yield and lower costs."

      }
    ];
  }

  return (
    <section className='FeaturedProductSection' >
      {customSlides?.length ? <>
      </> : <>
        <h2 className='floating montserrat-bold' data-aos={animation ? "fade-right" : ""} >Solar Panels</h2>
      </>}
      <Swiper
        slidesPerView={3}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}

        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        modules={[Pagination]}
        className="mySwiper featured-swiper"
        data-aos={animation ? "fade-up" : ""}
      >

        {slides.map((slide, index) => (
          <SwiperSlide className="list">
            <div key={index} className="item" >
              <div className="wrapper">
                <div className="container">
                  <div className="top">
                    <img src={slide.imageUrl} alt={`Slide ${index}`} />
                  </div>
                  <div className="bottom">
                    <div className="left">
                      <Link to={slide.link ? slide.link : '/coming-soon'}>
                        <div className="details">
                          <h3 className='montserrat-regular'>{slide.title}</h3>
                          <img className='arrow-image-product-card' width="20" height="20" src="https://img.icons8.com/ios/50/FFFFFF/long-arrow-right--v1.png" alt="long-arrow-right--v1" />
                        </div>
                        {/* <div className="buy"><i className="material-icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 512 512" id="cart"><path fill="#FF973B" d="M169.6 377.6c-22.882 0-41.6 18.718-41.6 41.601 0 22.882 18.718 41.6 41.6 41.6s41.601-18.718 41.601-41.6c-.001-22.884-18.72-41.601-41.601-41.601zM48 51.2v41.6h41.6l74.883 151.682-31.308 50.954c-3.118 5.2-5.2 12.482-5.2 19.765 0 27.85 19.025 41.6 44.825 41.6H416v-40H177.893c-3.118 0-5.2-2.082-5.2-5.2 0-1.036 2.207-5.2 2.207-5.2l20.782-32.8h154.954c15.601 0 29.128-8.317 36.4-21.836l74.882-128.8c1.237-2.461 2.082-6.246 2.082-10.399 0-11.446-9.364-19.765-20.8-19.765H135.364L115.6 51.2H48zm326.399 326.4c-22.882 0-41.6 18.718-41.6 41.601 0 22.882 18.718 41.6 41.6 41.6S416 442.082 416 419.2c0-22.883-18.719-41.6-41.601-41.6z"></path></svg>
                      </i>
                      </div> */}
                      </Link>
                    </div>
                    <div className="right">
                      <div className="done"><i className="material-icons">done</i></div>


                    </div>
                  </div>
                </div>
                <div className="inside">
                  <div className="icon"><i className="material-icons">
                    <svg width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="info"><g fill="#000" data-name="Layer 2"><path d="M8 2a6 6 0 1 0 6 6 6 6 0 0 0-6-6Zm0 11a5 5 0 1 1 5-5 5 5 0 0 1-5 5Z"></path><path d="M8 6.85a.5.5 0 0 0-.5.5v3.4a.5.5 0 0 0 1 0v-3.4a.5.5 0 0 0-.5-.5zM8 4.8a.53.53 0 0 0-.51.52v.08a.47.47 0 0 0 .51.47.52.52 0 0 0 .5-.5v-.12A.45.45 0 0 0 8 4.8z"></path></g></svg>
                  </i></div>
                  <div className="contents lato-regular">
                    {slide.description}
                  </div>
                </div>
              </div>

            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
export default FeaturedProducts;