import React from 'react';
import './Announcement.css'
import { Button } from 'react-bootstrap';
import { Icon } from '../../components';
import NavBrand from '../Nav/Nav-Brand/Navbrand';
import { Link } from "react-router-dom";

const Announcement = () => {
    window.addEventListener('scroll', () => {
        const announcementParent = document.querySelector('.header__container');
        const scrollPercentage = (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100;
        if (window.screen.width < 800) {
            if (scrollPercentage >= 1) {
                announcementParent.style.top = '0';
            } else {
                announcementParent.style.top = '68px'; // Adjust initial position as needed
            }
        } else {
            if (scrollPercentage >= 1) {
                announcementParent.style.top = '0px';
            } else {
                announcementParent.style.top = '68px'; // Adjust initial position as needed
            }
        }
    });
    return (
        <div className='announcement-parent'>

            <div className='announcement-child'>
                <Icon name="call-alt-fill"></Icon>
                <p className='montserrat-bold'>(+61) 429 342 406</p>
            </div>
            <div className='announcement-child'>
                <NavBrand />
            </div>
            <div className='announcement-child'>
            <Link to="/get-quote"> <Button variant="secondary" className='montserrat-bold'>Get Quote</Button></Link>
            </div>
        </div>
    )
}

export default Announcement