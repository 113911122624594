import React from 'react'
import FeaturedProducts from '../../my-components/FeaturedProducts/FeaturedProducts';
import banner1 from '../../assets/homepage/rec_website_tp5.jpg';

const REC = () => {
    const slides = [
        {
            imageUrl: banner1,
            title: 'REC ',
            description: "Based on the multiple award-winning REC TwinPeak technology, REC TwinPeak 5 panels bring heightened efficiency to REC's portfolio. Building on REC's long experience with half-cut cell technology, the REC TwinPeak 5 Series uses p-type monocrystalline cells, offering an even greater efficiency and power, making this Series suitable for all applications and system sizes, anywhere in the world! REC TwinPeak 5 features REC's innovative design with the high panel efficiency and power output of monocrystalline cells, enabling you to get more out of the space available – helping to lower your energy bills and shorten the payback time through increased yield and lower costs.",
            link: '/products/rec-solar'
        }
    ];

    return (
        <div>
            <FeaturedProducts customSlides={slides} animation={false} />
        </div>
    )
}

export default REC