import React, { useState, useEffect } from 'react';
import './slider.css';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import banner1 from '../../assets/homepage/bannerHome1.jpg';
import banner2 from '../../assets/homepage/bannerHome2.jpg';
import banner3 from '../../assets/homepage/banner3.png';
import { Link } from "react-router-dom"

const Slider = () => {

  const slides = [
    {
      imageUrl: banner1,
      title: 'Welcome to R Solar',
      description: "Your One-Stop Destination for High-Quality Solar Panels and Energy Solutions. Explore cutting-edge technology, eco-friendly solutions, and expert advice to harness the power of the sun for a brighter, sustainable future.",
      buttonText: 'Request A Solar Proposal',
      buttonUrl: '#'
    },
    {
      imageUrl: banner2,
      title: 'Powering Tomorrow',
      description: "Join us in revolutionizing energy consumption and embracing renewable energy sources. Start your journey towards a greener tomorrow with SolarEco!",
      buttonText: 'Our Services',
      buttonUrl: '#'
    },
  ];



  return (
    
     <div data-aos="fade-up" className="custom-banner-section">
     <Swiper
       spaceBetween={30}
       centeredSlides={true}
       autoplay={{
         delay: 4000,
         disableOnInteraction: false,
       }}
       pagination={{
         clickable: true,
       }}
       loop={true}
       navigation={true}
       modules={[Autoplay, Pagination, Navigation]}
       className="mySwiper carousel banner-section-custom"
     >
       {slides.map((slide, index) => (
         <SwiperSlide className="list">
          <div key={index} className="item" >
            <img src={slide.imageUrl} alt={`Slide ${index}`} />
            <div className="content">
              <div className="title montserrat-bold">{slide.title}</div>
              <div className="des montserrat-regular">{slide.description}</div>
              <div class="buttons">
                  <Link to='/get-quote'>
                    <button className='lato-bold'>{slide.buttonText}</button>
                  </Link>

                </div>
              </div>

            </div>
          </SwiperSlide>
        ))}


      </Swiper>
    </div>
  );
};

export default Slider;
