import Control from '../Controls/Control';
import DrawerNav from '../DrawerNav/DrawerNav';
import NavBrand from '../Nav-Brand/Navbrand';
import Form from '../Search-Bar/Form';
import './Container.css'
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";


const Navtop = () => {
    return ( 
            <div className="nav__top__container">
                <div className="top__container d-flex" >
                    {/* <NavBrand /> */}
                    {/* <div className="form__container">
                        <Form />
                    </div> */}
                    {/* <div className="control__bar">
                        <Control />
                    </div> */}
                    <div className="drawer">
                        <DrawerNav />
                    </div>
                    <NavBrand />
                    <Link to="/get-quote"><Button variant="secondary" className='getQuoteBTN'>Get Quote</Button></Link> 
                </div>
            </div>
     );
}
 
export default Navtop;