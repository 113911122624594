import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './TeslaSolar.css';
import TeslaPDPDesk from '../../assets/productpages/Tesla_Battery.jpg';
import TeslaPDPMob from '../../assets/productpages/Tesla_Battery.jpg';
import TeslaMobSwiper1 from '../../assets/productpages/Tesla_Battery.jpg';
import TeslaMobSwiper2 from '../../assets/productpages/powerwall2img2mob.jpg'
import TeslaDeskSwiper1 from '../../assets/productpages/powerwall2img1.jpg'
import TeslaDeskSwiper2 from '../../assets/productpages/powerwall2img2.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade, Navigation, Pagination } from 'swiper/modules';

import teslaPowerwall from '../../assets/productpages/Tesla_Battery.jpg';

const TeslaSolar = () => {
  return (
    <div className='product-page-super-parent'>
      <div className="custom-banner-section carousel">
        <div className="list">
          <div className="item" >
          {/* <video autoPlay loop muted controls className='product-main-banner'>
            <source src={teslabanner} type="video/mp4"/>
          </video> */}
          <div className='DesktopBannerTesla'>
            <img src={TeslaPDPDesk} alt='DesktopBanner' width={1920} height={1080}/>
          </div>
          <div className='MobileBannerTesla'>
            <img src={TeslaPDPMob} alt='MobileBanner' width={720} height={1280}/>
          </div>
            <div className="content MobBannerContent">
              <div className="title montserrat-bold">Tesla Powerwall 2</div>
              <div className="des montserrat-regular">The Tesla Powerwall is a revolutionary home battery system designed to seamlessly integrate with your solar panels, allowing you to harness and store clean energy generated from the sun. </div>
              <div class="buttons">
                <Link to='/get-quote'>
                  <button className='lato-bold'>Get Started</button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-brand'>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-e4817c9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e4817c9" data-element_type="section">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b45e63c" data-id="b45e63c" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-9154ea2 elementor-widget elementor-widget-image" data-id="9154ea2" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container DeskTopTesla">
                    <img src={TeslaMobSwiper1} />
                  </div>
                  <div class="elementor-widget-container MobileTesla">
                    <img src={teslaPowerwall} />
                  </div>
                </div>
              </div>
              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4558e86" data-id="4558e86" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6b5e14b elementor-widget elementor-widget-heading" data-id="6b5e14b" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">ELEVATE YOUR HOME WITH SUSTAINABLE ENERGY</h2>		</div>
                  </div>
                  <div class="elementor-element elementor-element-3e6203e elementor-widget elementor-widget-text-editor" data-id="3e6203e" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>The Powerwall stores excess energy generated by your solar panels during the day, allowing you to use it at night or during periods of high energy demand. With a storage capacity of up to 13.5 kWh, the Powerwall ensures that you have a reliable source of clean energy whenever you need it.</p>						
                      <p>In the event of a power outage, the Powerwall automatically switches to backup mode, providing seamless and uninterrupted power to your home. Whether it's a storm, grid outage, or other unforeseen circumstances, you can rest easy knowing that your essential appliances will keep running.</p>
                      <p>Sleek and compact, the Powerwall can be easily installed on the wall of your home or garage, saving valuable space and blending seamlessly with your existing architecture. Its minimalist design and silent operation make it a discreet yet powerful addition to any household.</p>
                      <p>Whether you're looking to power a small home or a larger property, the Powerwall can be scaled to meet your specific energy needs. Multiple Powerwall units can be stacked together to create larger storage capacities, providing flexibility and scalability for any application.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <div className='product-usp-sectionDesk'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TeslaDeskSwiper2} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='product-usp-sectionMob'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} alt='MobileSwiper1' />
          </SwiperSlide>
          <SwiperSlide>
          <img  src={TeslaMobSwiper2} alt='MobileSwiper2' />
          </SwiperSlide>
        </Swiper>
      </div> */}
      <div className='product-specification'>
        <h2>Powerwall Specification</h2>
        <div className='specification-parent'>
          <div className='specification-child'>
            <h3>Power</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Energy Capacity</h5>
                <p>13.5kWh</p>
              </div>
              <div className='spec-card'>
                <h5>On-Grid Power</h5>
                <p>5 kW continues</p>
              </div>
              <div className='spec-card'>
                <h5>Backup Power</h5>
                <p>7 kW peak</p>
                <p>106A LRA motor start</p>
                <p>Seamless backup</p>
                <p>transition</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Features</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Size and Weight</h5>
                <p>L x W x D</p>
                <p>45.3 in x 29.6 in x 5.75 in</p>
                <p>251.3 lbs</p>
              </div>
              <div className='spec-card'>
                <h5>Scalable</h5>
                <p>Upto 10 units</p>
              </div>
              <div className='spec-card'>
                <h5>Installation</h5>
                <p>Floor or wall mounted</p>
                <p>Indoor or outdoor</p>
                <p>-4°F to 122°F</p>
                <p>Water and dust resistance</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Warranty</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Duration</h5>
                <p>10 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeslaSolar