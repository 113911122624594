import React from 'react';
import './OurEcoSystem.css'
import jinkoLogo from '../../assets/logos/jinkoLogo.png';
import RECLogo from '../../assets/logos/RECSolar.png';
import trinaLogo from '../../assets/logos/trinaLogo.png';
import SungrowLogo from '../../assets/logos/SungrowLogo.png';
import froniusLogo from '../../assets/logos/froniusLogo.png';
import enphaseLogo from '../../assets/logos/enphaseLogo.png';
import teslaLogo from '../../assets/logos/teslaLogo.png';
import bydLogo from '../../assets/logos/bydLogo.png';
import reclaimLogo from '../../assets/logos/reclaimLogo.png';
import istoreLogo from '../../assets/logos/istoreLogo.webp';
import { Link } from 'react-router-dom';

const OurEcoSystem = () => {
    return (
        <div className="our-eco-system">
            <div className='our-eco-system-left'>
                <h1 className='our-eco-system-heading montserrat-bold'>Our Ecosystem</h1>
                <p className='our-eco-system-textcontent lato-regular'>
                    We believe in the power of solar energy to transform our world for the better. By harnessing the abundant and renewable energy of the sun, we're not just providing sustainable solutions but also nurturing a healthier ecosystem for generations to come.
                    Our ecosystem includes renowned manufacturers like JinkoSolar, Trina Solar, and many others, known for their innovation, quality, and reliability in the solar energy sector.
                </p>
            </div>
            <div className='our-eco-system-right'>
                <div className='our-eco-system-brand-logo'>
                    <Link to="/collections/jinko"><img src={jinkoLogo} /></Link>
                </div>
                <div className='our-eco-system-brand-logo'>
                    <Link to="/collections/rec"><img src={RECLogo} /></Link>
                </div>
                <div className='our-eco-system-brand-logo'>
                    <Link to="/collections/trina"><img src={trinaLogo} /></Link>
                </div>
                <div className='our-eco-system-brand-logo'>
                    <Link to="/collections/sungrow"><img src={SungrowLogo} /></Link>
                </div>
                <div className='our-eco-system-brand-logo'>
                    <Link to="/collections/fronius"><img src={froniusLogo} /></Link>
                </div>
                <div className='our-eco-system-brand-logo'>
                    <Link to="/collections/enphase"><img src={enphaseLogo} /></Link>
                </div>
                <div className='our-eco-system-brand-logo'>
                    <Link to="/collections/tesla"><img src={teslaLogo} /></Link>
                </div>
                <div className='our-eco-system-brand-logo'>
                    <Link to="/collections/byd"><img src={bydLogo} /></Link>
                </div>
                <div className='our-eco-system-brand-logo'>
                    <Link to="/collections/reclaim"><img src={reclaimLogo} /></Link>
                </div>
                <div className='our-eco-system-brand-logo'>
                    <Link to="/collections/istore"><img src={istoreLogo} /></Link>
                </div>
            </div>
        </div>
    )
}

export default OurEcoSystem