import Router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
function App() {

  useEffect(() => {
    AOS.init({
      duration: 2000, // animation duration in milliseconds
      once: false, // whether animation should only happen once
    });
  }, []);
  return (
    <>
      <Router />
    </>
  );
}

export default App;
