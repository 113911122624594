import React, { useEffect } from 'react';
import './GoogleReviews.css'

const GoogleReviews = () => {

    useEffect(() => {
        setTimeout(() => {
            document.querySelector('.InfoContainer').nextElementSibling.querySelector('a:last-child')?.remove();
        }, 2000);
    }, [])
    return (
        <div className='custom-google-reviews' style={{ background: '#000' }} class="elfsight-app-4a805a0f-3d32-4713-a415-da081f34d241" data-elfsight-app-lazy></div>
    )
}

export default GoogleReviews