import './Landing.css'
import land from '../../assets/homepage/solarPanel.webm'
import { Link } from "react-router-dom"
import { Button } from "@mui/material";

const Landing = () => {
    const filename = land; 
    const extension = filename.split('.').pop().toLowerCase();

    let mediaDisplay;
    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        mediaDisplay = <img className="landing__image" src={filename} alt="Image" />;
    } else if (extension === 'mp4' || extension === 'webm' || extension === 'ogg') {
        mediaDisplay = <video playsinline autoPlay loop muted className="landing__image"><source src={filename} type={`video/${extension}`} />Your browser does not support the video tag.</video>;
    } else {
            mediaDisplay = <p>Unsupported file type</p>;
    }
    return ( 
        <div className="landing__container" data-aos="fade-up">
            <div className="landing__header__container">
                <div className="landing__header">
                    <h3 className="landing__header__discount montserrat-bold">ALL ROADS LEAD TO SOLAR!</h3>
                    <h1 className="landing__header__main montserrat-bold">Working for a Brighter & More Clean Future</h1>
                    <Link to="/get-quote">
                        <Button className='lato-bold landing__header__button' variant='outlined' sx={[ {width: '190px', height: '50px', borderRadius: '20px' , fontWeight: '700', backgroundColor: 'none', borderColor: 'black', color: 'black' }, {'&:hover': {  backgroundColor: "black" , color: "#FFE26E", borderColor: 'black'}}]}>Get Quote</Button>
                    </Link>
                </div>
            </div>
            <div className="landing__image__container">
                    {mediaDisplay}
            </div>
        </div>
     );
}
 
export default Landing;