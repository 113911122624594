import React from 'react';
import './TrustedCertified.css';
import banner1 from '../../assets/homepage/approvedSeller.webp';
import banner2 from '../../assets/homepage/TeslaPowerwallCertifiedInstaller.png';

const TrustedCertified = () => {
    return (
        <div className='wrapper InfoContainer'>
            <h2 className="section-title" data-aos="fade-right" >Established. Trusted. Certified.</h2>
            <div className='trusted-logo-parent' data-aos="fade-left">
                <div className='particular-logo'>
                    <img src={banner1}/>
                </div>
                <div className='particular-logo'>
                    <img src={banner2}/>
                </div>
            </div>
        </div>
    )
}

export default TrustedCertified