import { Fragment, useEffect, useState } from "react";

import 'swiper/css';
import './HomeCard.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import {  Pagination, Autoplay } from 'swiper/modules';
import { Link } from "react-router-dom";
import banner1 from '../../assets/homepage/forHome.jpg';
import banner2 from '../../assets/homepage/forOffice.jpg';


const slides = [
    {
      imageUrl: banner1,
      title: 'Solar Panels For Home',
      description: "We make solar technology easy to install and affordable for your"
     
    },
    {
      imageUrl: banner2,
      title: 'Solar Panels For Businesses',
      description: "Become a smart Business Owner, save money and help the environment with our solar technology."
     
    },
    
    
  ];



const HomeCard = () => {
   return ( 
    <div className="main-card-container what-are-looking-for-section">
        <h2 className='floating' data-aos="fade-right" >WHAT ARE YOU LOOKING FOR?</h2>
        {/* <h3 className='main-heading montserrat-bold record-breaking-text' data-aos="fade-left" >Record-Breaking Solar Panels</h3> */}
    <div className="flex card-wrapper-container">
 
 {/* <Swiper
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
        modules={[Autoplay , Pagination]}
        className="mySwiper-1"
      > */}

{slides.map((slide, index) => (
        //  <SwiperSlide className="list" >
            <div key={index}  className="card1 "  data-aos="fade-up" style={{ backgroundImage:`url(${slide.imageUrl})`}} >
                <h3 className="montserrat-regular card-sub-title-proposal-section">{slide.title}</h3>
                <p className="lato-regular card-sub-title-2-proposal-section">{slide.description}</p>
                <div className="card-button-wrapper">
                    <div className="curve crv-bottom"></div>
                    <div className="curve crv-right"></div>
                    <div className="card-button"> 
                    <Link to="/get-quote" className="lato-bold">REQUEST A SOLAR PROPOSAL</Link>
                    </div>
                </div>
                </div>
                ))}
          {/* </SwiperSlide> */}
           
      {/* </Swiper> */}

      </div>
      </div>
    );
}
 
export default HomeCard;